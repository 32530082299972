// Libs
import React, { useEffect, useState } from 'react';
// Hooks
import useTheme from 'hooks/useTheme';
// Components
import { Button, Divider, Modal, Progress, Span, Wrapper } from 'components';
// Services
import { ApiServiceV2 } from 'services';
// styles
import styles from '../PostV1.module.css';

const DisplayDetailsProgress = ({ record, showDetails, handleOnClick }) => {
	const { theme, isDarkTheme } = useTheme();
	const [reviewProgressDetails, setReviewProgressDetails] = useState([]);

	useEffect(() => {
		ApiServiceV2.get({
			url: `/collectorManager/v1/detailsReportStatus/${record.id}`,
		}).then(response => {
			setReviewProgressDetails(response);
		});
	}, [record.id]);

	return (
		<Modal
			title={null}
			visible={showDetails}
			footer={null}
			onCancel={handleOnClick}
			width='330px'
			centered={true}
			secondaryBackgroundColor={
				isDarkTheme
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor
			}
		>
			<Wrapper
				padding='10px'
				width='300px'
				height='auto'
				display='flex'
				flexDirection='column'
				maxWidth='300px'
				maxHeight='230px'
				overflowY={reviewProgressDetails.length > 3 ? 'scroll' : ''}
			>
				{reviewProgressDetails.map(({ userId, userName, reviewProgress }) => {
					return (
						<Wrapper
							key={userId}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='flexStart'
							gap='10px'
							width='100%'
							height='70px'
						>
							<div className={styles.userNameAndReviewProgress}>
								<Span ellipsis width='80%'>
									{userName}
								</Span>
								<Span width='20%'>{reviewProgress}%</Span>
							</div>
							<Progress
								percent={reviewProgress}
								status='active'
								strokeColor={theme._secondaryColor}
								size='small'
								showInfo={false}
							/>
						</Wrapper>
					);
				})}
			</Wrapper>
		</Modal>
	);
};

const ProgressBar = ({ record }) => {
	const { theme } = useTheme();
	const [{ reviewProgress, reviewTotal }, setReportStatus] = useState({
		reviewProgress: 0,
		reviewTotal: 0,
	});
	const [showDetails, setShowDetails] = useState(false);

	const handleOnClick = () => {
		setShowDetails(showDetails => !showDetails);
	};

	useEffect(() => {
		ApiServiceV2.get({
			url: `/collectorManager/v1/reportStatus/${record.id}`,
		}).then(res => {
			setReportStatus({
				reviewProgress: res.reviewProgress ?? '0.00',
				reviewTotal: res.reviewTotal,
			});
		});
	}, [record.id]);

	return (
		<Wrapper
			width='100%'
			bottom='0'
			padding='10px'
			gap='10px'
			flexDirection='column'
			alignItems='flex-start'
		>
			<Wrapper width='100%' padding='0' justifyContent='space-between'>
				<Span>{`Supervisado el ${reviewProgress}% de ${reviewTotal} registros`}</Span>

				{/* {reviewProgress > 0 && (
					<Button lettercase='none' onClick={handleOnClick}>
						<Span color={theme._secondaryColor}>Ver desglose</Span>
					</Button>
				)} */}
				{showDetails && (
					<DisplayDetailsProgress
						handleOnClick={handleOnClick}
						showDetails={showDetails}
						record={record}
					/>
				)}
			</Wrapper>
			<Progress
				percent={reviewProgress}
				status='active'
				strokeColor={theme._secondaryColor}
				size='small'
				showInfo={false}
			/>
		</Wrapper>
	);
};

export default ProgressBar;
