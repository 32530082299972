// Libs
import Immutable from 'immutable';
// Utils
import GlobalUtils from 'utils/GlobalUtils';

const IMPORT_STATUS = {
	LOADING: 'loading',
	SUCCESS: 'success',
	UNSUCCESS: 'unsuccess',
};

const _getFieldKey = field => `${field.id}Field`;
const _getValidationField = field => `${field.id}Validation`;
const _getUTCConvertedField = field => `${field.id}UTC`;

const getFormattedTableField = (field, fieldActionIds = {}) => ({
	...field,
	...GlobalUtils.checkObject(fieldActionIds[field.id]),
	width: field.width || 200,
	filterable: field.filterable !== undefined ? field.filterable : true,
	unselectable: field.unselectable,
});

export default class ImportUtils {
	constructor(orders, fields) {
		this.orders = orders;
		this.fields = fields;
	}

	getFormattedFields(fieldActionIds) {
		const formattedFields = this.fields.reduce(
			(acc, field) => {
				if (field.show || field.id === 'delete') {
					acc.push(getFormattedTableField(field, fieldActionIds));
					return acc;
				}
				for (const order of this.orders) {
					if (order[_getValidationField(field)] === IMPORT_STATUS.UNSUCCESS) {
						acc.push(getFormattedTableField(field, fieldActionIds));
						break;
					}
				}
				return acc;
			},
			[
				getFormattedTableField({
					id: 'index',
					name: '#',
					width: 50,
					type: 'numeric',
					filterable: false,
					unselectable: true,
				}),
			],
		);

		const hasWarrantyOrder = this.orders.find(o => !!o.warranty);
		if (hasWarrantyOrder)
			formattedFields.push(
				getFormattedTableField({
					id: 'warrantyResult',
					name: 'Garantía',
					width: 80,
					type: 'boolean',
					filterable: false,
					unselectable: true,
				}),
			);

		return formattedFields;
	}

	static getStatus() {
		return IMPORT_STATUS;
	}

	static getValidationField(field) {
		return _getValidationField(field);
	}

	static getTemplateValidatorFields(templateValidator) {
		if (
			!templateValidator ||
			!templateValidator.dataStructureObject ||
			!templateValidator.dataStructureObject.fields
		)
			return {};

		return templateValidator.dataStructureObject.fields.reduce((acc, field) => {
			acc[_getFieldKey(field)] = field;
			return acc;
		}, {});
	}

	static checkScrappedValueType({ value, castType }) {
		const castingTypes = {
			int: () => parseInt(value),
			decimal: () => Number(value).toFixed(2),
		};

		const checker = castingTypes[castType];
		if (!checker) return value;

		const checkedValue = checker();
		if (!checkedValue || checkedValue === 'NaN') return;

		return checkedValue;
	}

	static deleteUTCConvertedField({ order, field }) {
		if (field.type !== 'date' && field.type !== 'datetime') return order;
		delete order[_getUTCConvertedField(field)];
		return order;
	}
}
