import ApiService from 'services/apiService/apiService';

export const getReactions = async ({ resourceKey, resourceId }) => {
	return ApiService.fetch(ApiService.endpoints().GET_REACTIONS, {
		reqParams: {
			resourceKey,
			resourceId,
		},
	});
};

export const getReactionDetails = async ({
	reactionKey,
	resourceKey,
	resourceId,
}) => {
	return ApiService.fetch(ApiService.endpoints().GET_REACTION_DETAILS, {
		reqParams: {
			reactionKey,
			resourceKey,
			resourceId,
		},
	});
};

export const submitReaction = async ({
	resourceKey,
	resourceId,
	reactionId,
	rating = null,
}) => {
	return ApiService.fetch(ApiService.endpoints().SUBMIT_REACTION, {
		bodyParams: {
			resourceKey,
			resourceId,
			reactionId,
			rating,
		},
	});
};
