// Libs
import cx from 'classnames';
// Hooks
import useTheme from 'hooks/useTheme';
// Styles
import styles from './Caption.module.css';

const Caption = props => {
	const { theme, sharedStyles } = useTheme();
	return (
		<span
			onClick={props.onClick}
			style={{
				color: props.width ?? theme._primaryColor,
				width: props.width,
				minWidth: props.minWidth,
				fontWeight: props.fontWeight,
				margin: props.margin,
			}}
			className={cx(
				sharedStyles.standardTextStructure,
				styles.caption,
				theme.mediumEmphasisColor,
				{
					[sharedStyles.hoverable]: !!props.hoverable,
					[sharedStyles.ellipsis]: !!props.ellipsis,
					[sharedStyles.wordBreak]: !!props.wordBreak,
					[sharedStyles[`textAlign_${props.textAlign}`]]: !!props.textAlign,
					[theme.highEmphasisColor]: !!props.emphasis,
					[theme.hoverable]: !!props.hoverable,
					[theme.secondaryColor]: !!props.selected,
					[theme.inactiveColor]: !!props.disabled,
				},
			)}
		>
			{props.children}
		</span>
	);
};

export default Caption;
