// Libs
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// Utils
import MODULE_KEYWORDS from 'components/Modules/keywords';
import ModuleUtils from 'components/Modules/utils';
// Action
import {
	getProjects,
	resetProjects,
	resetOrderTargets,
	resetServices,
	resetDatasource,
	resetDisplay,
	resetActionForm,
	resetDataFilters,
} from 'core/actions';
// Selectors
import { selectSelectedOrderTargetFilterId } from 'core/selectors';
import {
	selectAccessControlState,
	selectAccessControlModules,
	selectSelectedSubModule,
	selectSelectedSubModuleId,
} from 'components/Modules/selectors';
import { selectIsMobile } from 'screens/Dashboard/selectors';
// Components
import {
	Wrapper,
	LogoIcon,
	OrderIcon,
	WarehouseIcon,
	BillingIcon,
	ReportIcon,
	ChartIcon,
	UserIcon,
	SupervisorIcon,
	Section,
	SectionTitle,
	ArticlesIcon,
	TransactionIcon,
	SerieIcon,
	FamilyArticlesIcon,
	Span,
} from 'components';
import Menu from 'components/Menu';
import KEYWORDS from '../../keywords';
import { ActionBuilder } from '../../ActionBuilder';

const { SubMenu } = Menu;
const { ROUTES } = KEYWORDS;

const SelectedIcon = ({ icon, size = 'normal', selected }) => {
	const {
		ORDERS,
		WAREHOUSES,
		INVENTORY,
		SERIALIZED,
		TRANSACTIONS,
		INVENTORY_FAMILY,
		BILLINGS,
		REPORTS,
		ANALYTICS,
		USERS,
		SUPERVISOR,
	} = MODULE_KEYWORDS.MODULES.KEYS;

	const Icon = {
		[ORDERS]: OrderIcon,
		[WAREHOUSES]: WarehouseIcon,
		[INVENTORY]: ArticlesIcon,
		[SERIALIZED]: SerieIcon,
		[TRANSACTIONS]: TransactionIcon,
		[INVENTORY_FAMILY]: FamilyArticlesIcon,
		[BILLINGS]: BillingIcon,
		[REPORTS]: ReportIcon,
		[ANALYTICS]: ChartIcon,
		[USERS]: UserIcon,
		[SUPERVISOR]: SupervisorIcon,
	}[icon];

	if (!Icon) return <OrderIcon button selected={selected} size={size} />;
	return <Icon button selected={selected} size={size} />;
};

const ModuleIcon = React.memo(
	({ module, selectedId, selectedOrderTargetFilterId }) => {
		const isSelected = React.useMemo(
			() => module.id === selectedId,
			[selectedId],
		);
		return (
			<Wrapper
				margin='10px 0'
				width='100%'
				padding='0 10px 0 0'
				justifyContent='space-between'
			>
				<Wrapper width='100%' padding='0' cursor='pointer'>
					<SelectedIcon
						icon={module.icon?.value ?? module.key}
						selected={isSelected}
					/>
					<Span margin='0 0 0 16px' selected={isSelected}>
						{module.name}
					</Span>
				</Wrapper>
				{isSelected && selectedOrderTargetFilterId && (
					<ActionBuilder
						moduleItemId={module.id}
						orderTargetId={selectedOrderTargetFilterId}
					/>
				)}
			</Wrapper>
		);
	},
);

const ModulePicker = React.memo(
	({
		// State
		isMobile,
		accessControlState,
		selectedSubModuleId,
		selectedSubModule,
		selectedOrderTargetFilterId,
		modules,
		resetProjects,
		resetOrderTargets,
		resetServices,
		resetDatasource,
		resetDisplay,
		resetActionForm,
		resetDataFilters,
	}) => {
		const history = useHistory();
		const isFetching = React.useMemo(
			() => accessControlState === MODULE_KEYWORDS.STATE.LOADING,
			[accessControlState],
		);

		const menuItems = React.useMemo(() => {
			const filteredModules = modules
				.filter(
					module => module.id !== 3 && module.id !== 5, // TODO: Borrar filtro de IDS conforme se vayan migrando a la versión 2 (Synapse Feed)
				)
				.filter(module => !!module.moduleItems.length); // Filtrar módulos sin moduleItems primero

			const filteredModulesWithItems = filteredModules.map(module => ({
				...module,
				moduleItems: module.moduleItems.filter(moduleItem => {
					// TODO: Borrar filtros de los moduleItems específicos dentro del módulo 4 (inventario)
					if (module.id === 4) {
						return ![8, 9].includes(moduleItem.id);
					}
					return true;
				}),
			}));

			const finalFilteredModules = filteredModulesWithItems.filter(
				module => !!module.moduleItems.length,
			);

			return finalFilteredModules.map(module =>
				module.moduleItems.length > 1 ? (
					<SubMenu key={module.key} title={module.name.toUpperCase()}>
						{module.moduleItems.map(moduleItem => (
							<Menu.Item
								key={moduleItem.key}
								style={{ padding: '0 10px' }}
								onClick={() => {
									const route = ModuleUtils.getRouteFromModuleItemKey(
										moduleItem.key,
									);
									history.push(`${ROUTES.FEED.PATH}/${route}`);
								}}
							>
								<ModuleIcon module={moduleItem} />
							</Menu.Item>
						))}
					</SubMenu>
				) : (
					<Menu.Item
						key={module.key}
						style={{ padding: '0 10px' }}
						onClick={() => {
							const route = ModuleUtils.getRouteFromModuleItemKey(
								module.moduleItems[0].key,
							);
							history.push(`${ROUTES.FEED.PATH}/${route}`);
						}}
					>
						<ModuleIcon module={module} />
					</Menu.Item>
				),
			);
		}, [modules]);

		if (isFetching)
			return (
				<Wrapper justifyContent={isFetching ? 'center' : 'flex-start'}>
					<LogoIcon spin={true} />
				</Wrapper>
			);

		return (
			<Section margin='0 0 16px 0'>
				<SectionTitle title='Módulo' empty={modules.length === 0} />
				{selectedSubModule ? (
					<Menu.Wrapper mode='inline'>
						<Menu.Item
							key={selectedSubModule.key}
							style={{ padding: '0 10px 0 0' }}
							onClick={() => {
								resetProjects();
								resetOrderTargets();
								resetServices();
								resetDataFilters();
								resetDatasource();
								resetDisplay();
								resetActionForm();
								history.push(ROUTES.FEED.PATH);
							}}
						>
							<ModuleIcon
								module={selectedSubModule}
								selectedId={selectedSubModuleId}
								selectedOrderTargetFilterId={selectedOrderTargetFilterId}
							/>
						</Menu.Item>
					</Menu.Wrapper>
				) : (
					<Menu.Wrapper mode='inline' style={{ width: 290 }}>
						{menuItems}
					</Menu.Wrapper>
				)}
			</Section>
		);
	},
);

const mapStateToProps = state => ({
	isMobile: selectIsMobile(state),
	accessControlState: selectAccessControlState(state),
	selectedSubModuleId: selectSelectedSubModuleId(state),
	selectedSubModule: selectSelectedSubModule(state),
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	modules: selectAccessControlModules(state),
});
const actions = {
	getProjects,
	resetProjects,
	resetOrderTargets,
	resetServices,
	resetDatasource,
	resetDisplay,
	resetActionForm,
	resetDataFilters,
};
export default connect(mapStateToProps, actions)(ModulePicker);
