// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
// Actions
import {
	getProjects,
	mutate1Object as mutate1ObjectInCore,
	resetDataFilters,
	resetDatasource,
} from 'core/actions';
import {
	selectProjects,
	selectProjectsIsFetching,
	selectSelectedProjectId,
} from 'core/selectors';
// Components
import {
	Wrapper,
	Avatar,
	InitialResourceIcon,
	LogoIcon,
	ProjectIcon,
	Caption,
	Section,
	SectionTitle,
	RadioV2,
	Span,
} from 'components';

const { DEPARTMENTS } = GENERAL.ENV;

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	item: {
		padding: '0',
		height: '24px',
		cursor: 'pointer',
	},
};

const ProjectItem = React.memo(
	({ style, project, selectedProjectId, onClick }) => {
		const isSelected = React.useMemo(
			() => project.id === selectedProjectId,
			[selectedProjectId],
		);
		if (!project?.icon) return;
		const { type, value } = project.icon;
		return (
			<Wrapper {...style}>
				<Wrapper
					padding='0'
					width='250px'
					justifyContent='stretch'
					alignItems='center'
					overflow='hidden'
					cursor='pointer'
					onClick={onClick}
				>
					{!type && <RadioV2 isSelected={isSelected} />}
					{type === DEPARTMENTS.ICON.TYPES.INITIAL && (
						<InitialResourceIcon
							selected={isSelected}
							initial={value ?? 'ND'}
							size='small'
						/>
					)}
					{type === DEPARTMENTS.ICON.TYPES.IMAGE && (
						<Avatar size='normal' fit avatar={{ src: value }} />
					)}
					{type === DEPARTMENTS.ICON.TYPES.ICON && (
						<ProjectIcon value={value} isSelected={isSelected} />
					)}
					<Span
						width='100%'
						margin={type && '0 0 0 10px'}
						hoverable
						selected={isSelected}
					>
						{project.name}
					</Span>
				</Wrapper>
			</Wrapper>
		);
	},
);

const _ProjectPicker = React.memo(
	({
		// Props
		moduleItemId,
		// State
		selectedProjectId,
		isFetching,
		projects,
		getProjects,
		mutate1ObjectInCore,
		resetDataFilters,
		resetDatasource,
	}) => {
		const [seeMore, setSeeMore] = React.useState('hide'); // hide, show, selected, collapsed

		// Get projects
		React.useEffect(() => {
			if (!moduleItemId) return;
			getProjects({ moduleItemId });
		}, [moduleItemId]);

		// Handle see more
		React.useEffect(() => {
			if (projects.length <= 3) setSeeMore('hide');
			else if (projects.length > 3 && seeMore === 'hide') {
				setSeeMore('show');
			}
		}, [seeMore, projects]);

		// Filter projects with see more feature
		const filteredProjects = React.useMemo(() => {
			if (projects.length > 3) {
				if (seeMore === 'selected') return projects;
				if (seeMore === 'collapsed') setSeeMore('show');
				return projects.filter((p, idx) => idx <= 2);
			}
			return projects;
		}, [seeMore, projects]);

		const containerStyle = React.useMemo(() => styles.container, [isFetching]);

		return (
			<Section>
				<SectionTitle title='Proyecto' empty={filteredProjects.length === 0} />
				<Wrapper {...containerStyle}>
					{isFetching && <LogoIcon spin={true} />}
					{!isFetching &&
						filteredProjects.map(project => (
							<ProjectItem
								key={project.id}
								project={project}
								selectedProjectId={selectedProjectId}
								style={styles.item}
								onClick={() => {
									if (project.id === selectedProjectId) return;
									resetDatasource();
									resetDataFilters();
									mutate1ObjectInCore('orderTargetFilters', {
										data: [],
									});
									mutate1ObjectInCore('projects', {
										selectedProjectId: project.id,
									});
								}}
							/>
						))}

					{seeMore === 'show' && seeMore !== 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('selected')}
						>
							Ver más
						</Caption>
					)}
					{seeMore === 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('collapsed')}
						>
							Ver menos
						</Caption>
					)}
				</Wrapper>
			</Section>
		);
	},
);

const mapStateToProps = state => ({
	selectedProjectId: selectSelectedProjectId(state),
	isFetching: selectProjectsIsFetching(state),
	projects: selectProjects(state),
});
const actions = {
	getProjects,
	mutate1ObjectInCore,
	resetDataFilters,
	resetDatasource,
};
export default connect(mapStateToProps, actions)(_ProjectPicker);
