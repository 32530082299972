// Libs
import React from 'react';
// Utils
import { getUIValueProp } from 'components/DataFieldManager/OnlyReadField/OnlyReadField';
// Components
import { TableVirtualized } from 'components';

const { Header } = TableVirtualized;

const HeaderRenderer = ({
	dataKey,
	label,
	field,
	datasource,
	filters,
	dateFormat,
	setState,
}) => (
	<Header
		filter={
			field.filterable
				? {
						dataKey: getUIValueProp(field) || dataKey,
						data: datasource,
						orderType: field.type,
						filters,
						dateFormat,
						onChangedFilter: updatedFilters =>
							setState(prev => ({ ...prev, filters: updatedFilters })),
					}
				: undefined
		}
	>
		{label}
	</Header>
);

export default HeaderRenderer;
