// Libs
import React from 'react';
// Components
import IconButton from '@mui/material/IconButton';
import FullStar from './FullStar';
import SmallFullStar from './SmallFullStar';

const SIZES = {
	SMALL: 'small',
	NORMAL: 'normal',
};

const FullStarIcon = ({ size, button, onClick }) =>
	button ? (
		<IconButton onClick={onClick}>
			{size === SIZES.SMALL ? <SmallFullStar /> : <FullStar />}
		</IconButton>
	) : size === SIZES.SMALL ? (
		<SmallFullStar />
	) : (
		<FullStar />
	);

export default FullStarIcon;
