// Libs
import React, { useContext, useEffect, useState } from 'react';
// Context
import { ModalBuilderContext } from 'core/context';
// Components
import { Reactions, Comments, AntButton, Badge } from 'components';
import ActionsBoxSkeleton from './ActionsBoxSkeleton';
import { ModalBuilderConfig } from 'core/ModalBuilder';
// Icons
import { PDFIcon } from 'components/Icons';
// Styles
import styles from './ActionsBox.module.css';

const Reports = ({ resourceId, odtId, reportCount, extReactions = [] }) => {
	const hasReports = reportCount > 0;
	const { setModal } = useContext(ModalBuilderContext);
	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();
	const [isApproved, setIsApproved] = useState(false);

	useEffect(() => {
		const _isApproved = !!extReactions.find(
			r => r.reactionKey === 'approval' && !!Number(r.count),
		);
		const _isDisapproved = !!extReactions.find(
			r => r.reactionKey === 'disapproval' && !!Number(r.count),
		);
		setIsApproved(_isApproved && !_isDisapproved);
	}, [extReactions]);

	const handleOnClickReportsButton = () =>
		setModal(modalKeys.orderReportsModal, {
			selectedOTDId: resourceId,
			selectedODTId: odtId,
		});

	if (!isApproved) return null;

	return (
		hasReports && (
			<AntButton onClick={handleOnClickReportsButton} type='link'>
				<div className={styles.buttonContentWrapper}>
					<Badge value={reportCount}>
						<PDFIcon />
					</Badge>
				</div>
			</AntButton>
		)
	);
};

export const DivWrapper = ({ className, justChildren, children, ...props }) =>
	justChildren ? (
		children
	) : (
		<div className={className} {...props}>
			{children}
		</div>
	);

const ActionsBox = React.memo(
	({
		autonomous,
		resourceKey,
		resourceId,
		odtId,
		commentCount,
		reportCount,
		externalReactionHandler,
		onClickCommentsButton,
		justButtons = false,
	}) => (
		<DivWrapper className={styles.actionsBox} justChildren={justButtons}>
			<DivWrapper
				className={styles.actionButtonsContainer}
				justChildren={justButtons}
			>
				<Comments
					autonomous={autonomous}
					resourceKey={resourceKey}
					resourceId={resourceId}
					commentCount={commentCount}
					onClickCommentsButton={onClickCommentsButton}
				/>

				<Reports
					resourceId={resourceId}
					odtId={odtId}
					reportCount={reportCount}
					extReactions={externalReactionHandler?.extReactions}
				/>
			</DivWrapper>

			<DivWrapper
				className={styles.reactionsContainer}
				justChildren={justButtons}
			>
				<Reactions
					resourceKey={resourceKey}
					resourceId={resourceId}
					externalReactionHandler={externalReactionHandler}
				/>
			</DivWrapper>
		</DivWrapper>
	),
);

ActionsBox.Skeleton = ActionsBoxSkeleton;

export default ActionsBox;
