// Libs
import React, { useContext } from 'react';
// Components
import { Span } from 'components';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Config
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';

const OrderId = ({ elementId, odtId, disableOverview }) => {
	const { setElementView } = useContext(ElementViewBuilderContext);
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const handleOpenOverview = () => {
		if (disableOverview) return;

		setElementView({
			elementViewKey: elementViewKeys.ORDER_ELEMENT,
			elementId,
		});
	};

	return <Span onClick={handleOpenOverview}>{odtId}</Span>;
};
export default OrderId;
