import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* GLOBAL */

  /* BODY */
  body {
    font-family: ${props => props.theme.fonts.family};
    box-sizing: border-box;
  }

  /* SCROLLBAR */
  *::-webkit-scrollbar {
    width: ${props => props.theme.scrollbar.width};
    height: ${props => props.theme.scrollbar.height};
  }
  *::-webkit-scrollbar-thumb {
    background: ${props => props.theme.scrollbar.thumb.background};
    border-radius: ${props => props.theme.scrollbar.thumb.borderRadius};
    box-shadow: ${props => props.theme.scrollbar.thumb.boxShadow};
  }
  *::-webkit-scrollbar-track {
    background: ${props => props.theme.scrollbar.track.background};
  }

  /* MATERIAL-UI COMPONENTS */

  /* CHIP */
  #chip {
    font-family: ${props => props.theme.fonts.secondaryFamily};
    font-size: ${props => props.theme.fonts.size.l};
    color: ${props => props.theme.chip.color};
    line-height: ${props => props.theme.chip.lineHeight};
    background-color: ${props => props.theme.chip.backgroundColor};
  }

  /* ANT COMPONENTS */

  /**
  * ANT-BUTTON
  */
  .ant-skeleton-element{
    width: 100%;
  }
  .ant-btn {
    background: transparent;
    border-color: transparent;
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-btn:hover {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
    border-color: transparent;
    color: ${({ $newTheme }) => $newTheme._highEmphasisColor};
  }
  .ant-btn-primary {
    border-color: ${({ $newTheme }) => $newTheme._borderColor};
  }
  .ant-btn-primary:hover {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }

  /**
  * ANT-INPUT
  */
  .anticon.ant-input-clear-icon, .ant-input-clear-icon{
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }

  /**
  * ANT-SELECT
  */
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    cursor: pointer;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: transparent;
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
    color: ${({ $newTheme }) => $newTheme._inactiveColor};
  }
  .ant-select-selection {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-select-disabled .ant-select-selection {
    background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
  }
  .ant-input-number-disabled .ant-input-number-input {
    background-color: ${({ $newTheme }) => $newTheme._disabledBackgroundColor};
  }
  .ant-cascader-picker-label {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }

  /**
  * ANT-DROPDOWN
  */
 .ant-dropdown-menu{
   color: ${({ $newTheme }) => $newTheme._primaryColor};
   background: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
 }
  .ant-select-dropdown {
    background: ${({ $newTheme }) => $newTheme._secondaryBackgroundColor};
  }
  .ant-select-item{
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }

  /**
  * ANT-PICKER
  */
  .ant-picker {
    background: transparent;
    border-color: ${({ $newTheme }) => $newTheme._borderColor};
  }
  .ant-picker-input > input {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-suffix {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-panel-container {
    background: ${({ $newTheme }) => $newTheme._secondaryBackgroundColor};
  }
  .ant-picker-content th {
    color: ${({ $newTheme }) => $newTheme._highEmphasisColor};
  }
  .ant-picker-header-view button {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-cell {
    color: ${({ $newTheme }) => $newTheme._inactiveColor};
  }
  .ant-picker-cell-in-view {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-header button {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-header button:hover {
    color: ${({ $newTheme }) => $newTheme._highEmphasisColor};
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-picker-clear {
    background: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-clear:hover {
    background: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
    background: ${({ $newTheme }) => $newTheme._surfaceBackgroundColor};
  }

  /**
  * ANT-SELECT-ARROW
  */
  .ant-select-arrow {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }

  #icon-standard {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }

  /**
  * ANT-MENU
  */
  .ant-menu-item > a {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-menu-item a {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-menu-vertical .ant-menu-item {
    height: ${props => props.theme.menu.item.height};
    line-height: ${props => props.theme.menu.item.lineHeight};
  }
  .ant-menu-submenu-title{
    padding: 0 !important;
  }
  .ant-menu-submenu-inline {
    padding: 0 10px !important;
  }
  .ant-menu-item-group-title{
    color: ${({ $newTheme }) => $newTheme._inactiveColor};
  }
  .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    color: ${({ $newTheme }) => $newTheme._mediumEmphasisColor};
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: ${({ $newTheme }) => $newTheme._highEmphasisColor};
  }
  /**
  * ANT-DESCRIPTIONS
  */
  .ant-descriptions-title{
    margin-bottom: 5px;
  }
  .ant-descriptions-item > span {
    display: block;
  }
  
  /**
  * ANT-CARD
  */
  .ant-card-actions > li {
    margin: 0;
  }

  /**
  * ANT-TOOLTIP
  */
   .ant-tooltip {
    max-width: fit-content;
  }
  .ant-tooltip-inner {
    background-color: ${props => props.theme.tooltip.backgroundColor};
  }

  /**
  * ANT-SPIN
  */
  .ant-spin-nested-loading {
    width: ${props => props.theme.spin.width};
  }

  /**
  * ANT-COLLAPSE
  */
  .ant-collapse-borderless > .ant-collapse-item{
    border-bottom: ${props => props.theme.collapse.borders.bottom}
  }

  
  .ant-popover-inner-content {
      background: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
  }
  .ant-popover-message {
    color: ${({ $newTheme }) => $newTheme._primaryColor};
  }
        
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${({ $newTheme }) => $newTheme._primaryBackgroundColor};
  }
  
`;

export default GlobalStyle;
