// Libs
import React from 'react';
import { useTheme } from 'styled-components';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
// Utils
import { filterTransactions } from 'utils/filters/Warehouses/transactions';
import GENERAL from 'utils/constants/general';
// Components
import {
	Wrapper,
	InputNumber,
	Button,
	Flag,
	Span,
	TableVirtualized,
	GetSerieHistory,
	GetOrderInfo,
	CloseIcon,
	LogoIcon,
} from 'components';
import Select from 'components/Select';
import {
	filterInventoryToTransferSearchInData,
	getPreview,
	isSerieInTransaction,
	onScanInventoryTransaction,
	onDeleteSerie,
	onAddSerie,
} from '../../../utils';
// Styles
import '../../../css/Transactions.css';

const { ENV } = GENERAL;
const { Option } = Select;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

// TODO: Revisar si tengo que poner esto también en la versión mobile (Era sobre el módulo para recibir equipos desinstalado que quedó a medias)
function isViewPreview(createTransaction, inventoryToTransferManage) {
	return (
		createTransaction.preview &&
		inventoryToTransferManage.viewOptions.devices === 'found'
	);
}

function InventoryToTransferDesktop(props) {
	const theme = useTheme();

	if (
		!props.createTransaction.mode ||
		!props.createTransaction.incomeMethod ||
		!props.createTransaction.owhOwnerId ||
		!props.createTransaction.owarehouseId ||
		!props.createTransaction.dwarehouseId ||
		!props.createTransaction.signer
	) {
		return <Redirect to={ENV.ROUTES.PATHS.WAREHOUSES_TRANSACTIONS_DESKTOP} />;
	}

	const {
		transactions,
		inventoryToTransferManage,
		createTransaction,
		createPackage,
		warehousePackages,
		warehouseAreas,
		warehouseZones,
		itemStates,
		mutate1Object,
		mutate1ObjectApi,
		sendToast,
		onGetOrderDataFromUninstalledSerie,
		profile,
	} = props;
	const serialized = [...createTransaction.serialized];
	const articles = [...createTransaction.articles];
	// ALL ITEMS
	const allItems =
		inventoryToTransferManage.viewOptions.devices === 'found'
			? transactions.inventory.data
			: createTransaction.notFoundSerialized;

	// Search in data
	let filteredInventoryItems = filterInventoryToTransferSearchInData(
		allItems,
		inventoryToTransferManage.searchInData.searchBy,
		inventoryToTransferManage.searchInData.data,
	);

	// Is Preview?
	if (isViewPreview(createTransaction, inventoryToTransferManage)) {
		var previewItems = getPreview(filteredInventoryItems, serialized, articles);
		filteredInventoryItems = previewItems;
	}

	// Filters in table (Only Desktop)
	filteredInventoryItems = filterTransactions(
		filteredInventoryItems,
		inventoryToTransferManage.filters,
	);

	// HEADER
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: isViewPreview(createTransaction, inventoryToTransferManage)
						? previewItems
						: allItems,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: inventoryToTransferManage.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('inventoryToTransferManage', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};
		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };

		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getStateCellRendered({ rowData }) {
		const idx = _.findIndex(serialized, serie => serie.serie === rowData.serie);

		return (
			<div className='desktop-transactions-table-index-cell-container'>
				{idx === -1 ? (
					<div>{rowData.state_name}</div>
				) : (
					<Select
						style={{ width: '100%' }}
						placeholder={`${rowData.state_name}`}
						onChange={value => {
							serialized[idx].state_id = value;
							mutate1Object('createTransaction', { serialized });
						}}
					>
						{itemStates.map((state, idx) => (
							<Option key={idx} value={state.id}>
								<em>{state.name}</em>
							</Option>
						))}
					</Select>
				)}
			</div>
		);
	}

	function getValueCellRendered({ rowData }) {
		const idx = _.findIndex(articles, article =>
			rowData.serie_id
				? article.serie_id === rowData.serie_id
				: article.item_id === rowData.item_id,
		);

		function onChangeArticleQuantity(value) {
			if (!value || value === 0) {
				if (idx !== -1)
					mutate1Object('createTransaction', {
						articles: articles.filter(article =>
							rowData.serie_id
								? article.serie_id !== rowData.serie_id
								: article.item_id !== rowData.item_id,
						),
					});
			} else {
				if (idx === -1) {
					const packages = warehousePackages.data.filter(
						pkg => pkg.item_id === rowData.item_id,
					);
					articles.push({
						item_id: rowData.item_id,
						serie_id: rowData.serie_id,
						amount: value,
						warehouse_package_id:
							createTransaction.mode === 'trsReceive' && packages.length === 1
								? packages[0].id
								: undefined,
					});
				} else {
					articles[idx].amount = value;
				}
				mutate1Object('createTransaction', { articles });
			}
		}

		return (
			<div className='desktop-stock-table-index-cell-container'>
				{!rowData.is_serialized ? (
					<InputNumber
						placeholder='Cantidad'
						value={idx !== -1 ? articles[idx].amount : undefined}
						min={0}
						max={rowData.amount}
						onChange={onChangeArticleQuantity}
					/>
				) : isSerieInTransaction(serialized, rowData.serie) ? (
					<Button
						className='desktop-transactions-button-add-delete-serie'
						style={{ color: 'darkgray' }}
						onClick={() => {
							onDeleteSerie(
								rowData.serie,
								inventoryToTransferManage,
								serialized,
								mutate1Object,
							);
							mutate1ObjectApi('transactions', {
								inventory: {
									...transactions.inventory,
									data: transactions.inventory.data.map(item => {
										if (item.serie === rowData.serie) {
											item.order_id = undefined;
											item.odt_id = undefined;
											item.expert_name = undefined;
											item.client_name = undefined;
										}
										return item;
									}),
								},
							});
						}}
					>
						Quitar
					</Button>
				) : (
					((createTransaction.mode === 'trsReceive' &&
						createPackage.created &&
						createPackage.serialized) ||
						createTransaction.mode === 'trsSend') && (
						<Button
							className='desktop-transactions-button-add-delete-serie'
							onClick={() => {
								if (createTransaction.mode === 'trsReceive') {
									onScanInventoryTransaction(
										createTransaction,
										createPackage,
										inventoryToTransferManage,
										transactions,
										serialized,
										mutate1Object,
										sendToast,
										onGetOrderDataFromUninstalledSerie,
										rowData.serie,
									);
								} else if (createTransaction.mode === 'trsSend') {
									onAddSerie(
										rowData.item_id,
										rowData.serie,
										rowData.state_id,
										createPackage.id,
										serialized,
										mutate1Object,
									);
								}
							}}
						>
							Agregar
						</Button>
					)
				)}
			</div>
		);
	}

	function getPackageCellRenderer({ rowData }) {
		if (createTransaction.mode === 'trsSend') {
			return (
				<div className='desktop-transactions-table-index-cell-container'>
					<div>{rowData.warehouse_package_id}</div>
				</div>
			);
		} else if (createTransaction.mode === 'trsReceive') {
			let packages = [];
			let itmIdx = -1;
			let hidePackage = true;
			let package_id;

			if (rowData.is_serialized) {
				itmIdx = _.findIndex(
					serialized,
					serie => serie.serie === rowData.serie,
				);
				if (itmIdx !== -1) package_id = serialized[itmIdx].warehouse_package_id;
			} else {
				packages = warehousePackages.data.filter(
					pkg => pkg.item_id === rowData.item_id,
				);
				itmIdx = _.findIndex(articles, art => art.item_id === rowData.item_id);
				if (itmIdx !== -1) package_id = articles[itmIdx].warehouse_package_id;
			}

			if (itmIdx !== -1) hidePackage = false;

			return (
				<div className='desktop-transactions-table-index-cell-container'>
					{hidePackage ? (
						<div />
					) : rowData.is_serialized ? (
						<Cell>
							<Span>{package_id}</Span>
						</Cell>
					) : packages.length === 1 ? (
						<Cell>
							<Span>{packages[0].id}</Span>
						</Cell>
					) : (
						<Button
							className='desktop-transactions-button-add-delete-serie'
							onClick={() => {
								if (createPackage.created && createPackage.serialized)
									sendToast({
										message: 'Primero complete el paquete de series abierto',
										type: 'warn',
									});
								else
									mutate1Object('createPackage', {
										operation: createTransaction.mode,
										isOpen: true,
										serialized: false,
										id: undefined,
										item_id: rowData.item_id,
										state_id: undefined,
										amount: undefined,
										warehouse_area_id: undefined,
										warehouse_zone_id: undefined,
										created: false,
										mustCreateZone: false,
										mustCreateArea: false,
										zone: undefined,
										area: undefined,
									});
							}}
						>
							Nuevo
						</Button>
					)}
				</div>
			);
		}
	}

	function getAreaCellRenderer({ rowData }) {
		let itmIdx = -1;
		let packageIdx = -1;
		let areaIdx = -1;

		if (createTransaction.mode === 'trsSend') {
			packageIdx = _.findIndex(
				warehousePackages.data,
				pkg => pkg.id === rowData.warehouse_package_id,
			);
		} else if (createTransaction.mode === 'trsReceive') {
			if (rowData.is_serialized) {
				itmIdx = _.findIndex(
					serialized,
					serie => serie.serie === rowData.serie,
				);
				if (itmIdx !== -1)
					packageIdx = _.findIndex(
						warehousePackages.data,
						pkg => pkg.id === serialized[itmIdx].warehouse_package_id,
					);
			} else {
				itmIdx = _.findIndex(articles, art => art.item_id === rowData.item_id);
				if (itmIdx !== -1)
					packageIdx = _.findIndex(
						warehousePackages.data,
						pkg => pkg.id === articles[itmIdx].warehouse_package_id,
					);
			}
		}
		if (packageIdx !== -1)
			areaIdx = _.findIndex(
				warehouseAreas.data,
				area =>
					area.id === warehousePackages.data[packageIdx].warehouse_area_id,
			);

		return (
			<div className='desktop-transactions-table-index-cell-container'>
				<div>
					{areaIdx !== -1 ? warehouseAreas.data[areaIdx].name : undefined}
				</div>
			</div>
		);
	}

	function getZoneCellRenderer({ rowData }) {
		let itmIdx = -1;
		let packageIdx = -1;
		let areaIdx = -1;
		let zoneIdx = -1;

		if (createTransaction.mode === 'trsSend') {
			packageIdx = _.findIndex(
				warehousePackages.data,
				pkg => pkg.id === rowData.warehouse_package_id,
			);
		} else if (createTransaction.mode === 'trsReceive') {
			if (rowData.is_serialized) {
				itmIdx = _.findIndex(
					serialized,
					serie => serie.serie === rowData.serie,
				);
				if (itmIdx !== -1)
					packageIdx = _.findIndex(
						warehousePackages.data,
						pkg => pkg.id === serialized[itmIdx].warehouse_package_id,
					);
			} else {
				itmIdx = _.findIndex(articles, art => art.item_id === rowData.item_id);
				if (itmIdx !== -1)
					packageIdx = _.findIndex(
						warehousePackages.data,
						pkg => pkg.id === articles[itmIdx].warehouse_package_id,
					);
			}
		}
		if (packageIdx !== -1)
			areaIdx = _.findIndex(
				warehouseAreas.data,
				area =>
					area.id === warehousePackages.data[packageIdx].warehouse_area_id,
			);
		if (areaIdx !== -1)
			zoneIdx = _.findIndex(
				warehouseZones.data,
				zone => zone.id === warehouseAreas.data[areaIdx].warehouse_zone_id,
			);

		return (
			<div className='desktop-transactions-table-index-cell-container'>
				<div>
					{zoneIdx !== -1 ? warehouseZones.data[zoneIdx].name : undefined}
				</div>
			</div>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedItems = [...inventoryToTransferManage.selectedItems];
		let styleRow = { ...style };

		// Is transaction selected?
		const idx = _.findIndex(
			selectedItems,
			itemCode => itemCode === rowData.item_code,
		);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	// GET ORDER DATA
	function getOrderData({ rowData, dataKey }) {
		if (inventoryToTransferManage.getSerieOrderData.serie === rowData.serie) {
			return (
				<Cell>
					<Wrapper padding='0' width='65%' justifyContent='center'>
						<LogoIcon spin button />
					</Wrapper>
				</Cell>
			);
		}
		if (dataKey === 'odt_id') {
			return (
				<Cell color={theme.colors.text.high}>
					<GetOrderInfo order_id={rowData.order_id} odt_id={rowData.odt_id} />
				</Cell>
			);
		}
		return <Cell>{rowData[dataKey]}</Cell>;
	}

	// GET SERIE
	function getSerie({ rowData }) {
		return (
			<Cell color={theme.colors.text.high}>
				<GetSerieHistory serie={rowData.serie} />
			</Cell>
		);
	}

	// Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			{inventoryToTransferManage.viewOptions.devices === 'notFound' ? (
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight - 115}
					headerHeight={44}
					rowHeight={40}
					rowCount={filteredInventoryItems.length}
					rowGetter={({ index }) => filteredInventoryItems[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={60}
						headerRenderer={({ label }) => (
							<Header justifyContent='space-around'>{label}</Header>
						)}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='serie'
						label='Serie'
						width={180}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getSerie}
					/>
					<Column
						dataKey=''
						label='Delete'
						width={180}
						headerRenderer={({ label }) => <Header>{label}</Header>}
						cellRenderer={({ rowData }) => (
							<Cell>
								<CloseIcon
									button
									onClick={() => {
										const notFoundSerialized =
											createTransaction.notFoundSerialized.filter(
												row => row.serie !== rowData.serie,
											);
										mutate1Object('createTransaction', {
											notFoundSerialized,
										});
										if (notFoundSerialized.length === 0) {
											mutate1Object('inventoryToTransferManage', {
												viewOptions: {
													...inventoryToTransferManage.viewOptions,
													devices: 'found',
												},
											});
										}
									}}
								/>
							</Cell>
						)}
					/>
				</Table>
			) : (
				<Table
					highBackground
					width={document.documentElement.clientWidth}
					height={document.documentElement.clientHeight * 0.83}
					headerHeight={44}
					rowHeight={40}
					rowCount={filteredInventoryItems.length}
					rowGetter={({ index }) => filteredInventoryItems[index]}
					rowRenderer={getRowRenderer}
				>
					<Column
						dataKey='index'
						label='#'
						width={60}
						headerRenderer={({ label }) => (
							<Header justifyContent='space-around'>{label}</Header>
						)}
						cellRenderer={getIndexCellRenderer}
					/>
					<Column
						dataKey='item_code'
						label='Código'
						width={125}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='item_name'
						label='Nombre'
						width={300}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					<Column
						dataKey='serialized'
						label='Serializado'
						width={125}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{inventoryToTransferManage.viewOptions.devices === 'found' && (
						<Column
							dataKey=''
							label='Valor'
							width={125}
							headerRenderer={props => getHeaderRenderer(props, 'numeric')}
							cellRenderer={getValueCellRendered}
						/>
					)}
					<Column
						dataKey='amount'
						label='Cantidad'
						width={110}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
					/>
					<Column
						dataKey='serie'
						label='Serie'
						width={180}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getSerie}
					/>
					<Column
						dataKey='state_name'
						label='Estado'
						width={120}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getStateCellRendered}
					/>
					<Column
						dataKey='warehouse_name'
						label='Almacén'
						width={180}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
					/>
					{createTransaction.mode === 'trsReceive' && (
						<Column
							dataKey='odt_id'
							label='Orden'
							width={180}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
							cellRenderer={getOrderData}
						/>
					)}
					{createTransaction.mode === 'trsReceive' && (
						<Column
							dataKey='client_name'
							label='Cliente'
							width={180}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
							cellRenderer={getOrderData}
						/>
					)}
					{createTransaction.mode === 'trsReceive' && (
						<Column
							dataKey='expert_name'
							label='Técnico'
							width={180}
							headerRenderer={props => getHeaderRenderer(props, 'text')}
							cellRenderer={getOrderData}
						/>
					)}
					<Column
						dataKey='zone_name'
						label='Zona'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getZoneCellRenderer}
					/>
					<Column
						dataKey='area_name'
						label='Area'
						width={130}
						headerRenderer={props => getHeaderRenderer(props, 'text')}
						cellRenderer={getAreaCellRenderer}
					/>
					<Column
						dataKey='warehouse_package_id'
						label='Paquete'
						width={100}
						headerRenderer={props => getHeaderRenderer(props, 'numeric')}
						cellRenderer={getPackageCellRenderer}
					/>
				</Table>
			)}
		</Wrapper>
	);
}

export default InventoryToTransferDesktop;
