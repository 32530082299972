// Libs
import React, { useContext } from 'react';
import { connect } from 'react-redux';
// Context
import { ModalBuilderContext } from 'core/context';
// Selectors
import { selectScreenKey, selectDatasourceData } from 'core/selectors';
// Constants
import GENERAL from 'utils/constants/general';
// Components
import { CommentsBox, Wrapper, CloseIcon, Modal } from 'components';
import { SupervisorPostV1 } from 'core/ElementBuilder/elements/Supervisor';
// Styles
import styles from './OrderExpanded.module.css';
import { Header as HeaderSupervisor } from 'core/ElementBuilder/elements/Supervisor/Post/common';

const { RESOURCES } = GENERAL.ENV;

const OrderExpanded = ({ screenKey, supervisorControl }) => {
	const { payload: modalPayload, cleanModal } = useContext(ModalBuilderContext);

	const record = React.useMemo(
		() => supervisorControl.find(r => r.id === modalPayload.selectedOTDId),
		[modalPayload.selectedOTDId, supervisorControl],
	);

	return !record ? null : (
		<Modal
			title={null}
			closable={false}
			centered={false}
			visible={true}
			onCancel={cleanModal}
			footer={null}
		>
			<Wrapper
				mediumBackground
				padding='0'
				margin='0'
				flexWrap='wrap'
				overflow='auto'
				width='100%'
				gap='10px'
				position='relative'
			>
				<HeaderSupervisor isMoreActions={false} record={record} />
				<div className={styles.commentsBoxContainer}>
					<CommentsBox
						resourceKey={RESOURCES.KEYS.PHOTO_REPORT}
						resourceId={record.id}
						showReplyBox={true}
					/>
				</div>
				<Wrapper padding='0' position='absolute' right='10px' top='7px'>
					<CloseIcon button onClick={cleanModal} />
				</Wrapper>
			</Wrapper>
		</Modal>
	);
};

const mapStateToProps = state => ({
	screenKey: selectScreenKey(state),
	supervisorControl: selectDatasourceData(state),
});
export default connect(mapStateToProps, null)(OrderExpanded);
