// Libs
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// Utils
import { debounce } from 'utils/libs';
// Actions
import {
	mutate1Object as mutate1ObjectInCore,
	mutateSubDataFilter,
	mutateUserParams,
	resetCustomFilterPropsInDataFilter,
	resetDataFilters,
} from 'core/actions';
import { getMyDatasourceAccess } from 'components/Modules/actions';
// Selectors
import {
	selectDataFilters,
	selectDataFiltersIsFetching,
	selectDataFilterUserParams,
	selectSelectedDataFilterId,
	selectSelectedOrderTargetFilterId,
} from 'core/selectors';
// Components
import {
	Wrapper,
	LogoIcon,
	Tooltip,
	Caption,
	RadioV2,
	DatePicker,
	SectionTitle,
	Section,
	Span,
} from 'components';
// import { ActionBuilder } from 'core/ActionBuilder';

const styles = {
	container: {
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	item: {
		padding: '0',
		margin: '0 0 5px 0',
	},
};

const getPickerName = picker => {
	if (picker === 'week') return 'Semana';
	if (picker === 'month') return 'Mes';
	if (picker === 'year') return 'Año';
	return 'Desde';
};

const DataFilterItem = React.memo(
	({
		style,
		filterItem,
		moduleItemId,
		selectedOrderTargetFilterId,
		selectedDataFilterId,
		onClick,
	}) => {
		const { id, name, description, actions } = filterItem;

		const isSelected = React.useMemo(
			() => id === selectedDataFilterId,
			[selectedDataFilterId],
		);

		return (
			<Wrapper style={style}>
				<Wrapper padding='0' width='100%'>
					<Tooltip title={description} placement='right'>
						<Wrapper
							padding='0'
							width='250px'
							height='20px'
							justifyContent='stretch'
							alignItems='center'
							overflow='hidden'
							onClick={onClick}
						>
							<RadioV2 isSelected={isSelected} />
							<Span width='100%' hoverable selected={isSelected}>
								{name}
							</Span>
						</Wrapper>
					</Tooltip>
					{/* {isSelected && moduleItemId && selectedOrderTargetFilterId && (
						<ActionBuilder
							moduleItemId={moduleItemId}
							orderTargetId={selectedOrderTargetFilterId}
						/>
					)} */}
				</Wrapper>
			</Wrapper>
		);
	},
);

const _DatasourceAccessPicker = React.memo(
	({
		// Props
		moduleItemId,
		// State
		selectedDataFilterId,
		selectedOrderTargetFilterId,
		isFetching,
		dataFilters,
		dataFilterUserParams,
		// Actions
		getMyDatasourceAccess,
		mutate1ObjectInCore,
		mutateUserParams,
		resetCustomFilterPropsInDataFilter,
		resetDataFilters,
	}) => {
		const { RangePicker } = DatePicker;

		const [seeMore, setSeeMore] = React.useState('hide'); // hide, show, selected, collapsed

		// Get available data filters
		const getDebouncedDataFilters = React.useCallback(
			debounce(callback => callback(), 200),
			[],
		);

		React.useEffect(() => {
			let isMounted = true;
			getDebouncedDataFilters(() => {
				if (!moduleItemId || !selectedOrderTargetFilterId) return;
				getMyDatasourceAccess({
					moduleItemId,
					orderTargetId: selectedOrderTargetFilterId,
					onLoading: () => [resetDataFilters({ isFetching: true })],
					onSuccess: dataFilters => {
						if (!isMounted) return;
						const defaultSelectedDataFilterItem =
							dataFilters.find(df => !!df?.config?.default) || dataFilters[0];

						return [
							mutate1ObjectInCore('dataFilters', {
								data: dataFilters,
								isFetching: false,
								selectedDataFilterId: defaultSelectedDataFilterItem?.id,
								reloadSubDataFilters: !!dataFilters.length,
								config: defaultSelectedDataFilterItem?.config || {},
							}),
							mutate1ObjectInCore('datasource', {
								reload: !!defaultSelectedDataFilterItem,
							}),
						];
					},
				});
			});
			return () => {
				isMounted = false;
			};
		}, [selectedOrderTargetFilterId]);

		// See more
		React.useEffect(() => {
			if (dataFilters.length <= 3) setSeeMore('hide');
			else if (dataFilters.length > 3 && seeMore === 'hide') {
				setSeeMore('show');
			}
		}, [seeMore, dataFilters]);

		// Filter items with seeMore
		const filteredDataFilters = React.useMemo(() => {
			if (dataFilters.length > 3) {
				if (seeMore === 'selected') return dataFilters;
				if (seeMore === 'collapsed') setSeeMore('show');
				return dataFilters.filter((f, idx) => idx <= 2);
			}
			return dataFilters;
		}, [seeMore, dataFilters]);

		const containerStyle = React.useMemo(() => styles.container, [isFetching]);

		const filterParams = React.useMemo(
			() => dataFilters.find(df => df.id === selectedDataFilterId)?.params,
			[selectedDataFilterId, dataFilters],
		);

		React.useEffect(() => {
			if (!filterParams?.fromDateParam?.defaultDateValue) return;
			mutateUserParams({
				fromDateParam: moment().subtract(
					filterParams.fromDateParam.defaultDateValue?.value || 1,
					filterParams.fromDateParam.defaultDateValue?.unit || 'day',
				),
				toDateParam: moment(),
			});
		}, [JSON.stringify(filterParams?.fromDateParam?.defaultDateValue)]);

		return (
			<Section>
				<SectionTitle title='Filtro' empty={filteredDataFilters.length === 0} />
				<Wrapper {...containerStyle}>
					{isFetching && <LogoIcon spin={true} />}
					{!isFetching &&
						filteredDataFilters.map(filterItem => (
							<DataFilterItem
								key={filterItem.id}
								style={styles.item}
								filterItem={filterItem}
								moduleItemId={moduleItemId}
								selectedOrderTargetFilterId={selectedOrderTargetFilterId}
								selectedDataFilterId={selectedDataFilterId}
								onClick={() => {
									if (filterItem.id === selectedDataFilterId) return;
									mutate1ObjectInCore('dataFilters', {
										lastRowId: 0,
										selectedDataFilterId: filterItem.id,
										reloadSubDataFilters: true,
										config: filterItem?.config || {},
									});
									mutate1ObjectInCore('datasource', {
										reload: true,
										data: [],
									});
									resetCustomFilterPropsInDataFilter(filterItem.id);
								}}
							/>
						))}

					{seeMore === 'show' && seeMore !== 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('selected')}
						>
							Ver más
						</Caption>
					)}
					{seeMore === 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('collapsed')}
						>
							Ver menos
						</Caption>
					)}
				</Wrapper>

				{/* RANGE PICKER */}
				{filterParams?.fromDateParam && filterParams?.toDateParam && (
					<RangePicker
						style={{ marginTop: '10px' }}
						placeholder='Seleccione el rango'
						value={[
							dataFilterUserParams.fromDateParam,
							dataFilterUserParams.toDateParam,
						]}
						onChange={range => {
							const [fromDateParam, toDateParam] = range || [];
							mutateUserParams({ fromDateParam, toDateParam });
							setTimeout(() => {
								mutate1ObjectInCore('dataFilters', {
									lastRowId: 0,
									reloadSubDataFilters: true,
								});
								mutate1ObjectInCore('datasource', {
									reload: true,
								});
							}, 400);
						}}
					/>
				)}

				{/* FROM DATE PICKER */}
				{filterParams?.fromDateParam && !filterParams?.toDateParam && (
					<DatePicker
						picker={filterParams?.fromDateParam.picker}
						style={{ marginTop: '10px' }}
						placeholder={getPickerName(filterParams?.fromDateParam.picker)}
						value={dataFilterUserParams.fromDateParam}
						onChange={fromDateParam => {
							mutateUserParams({ fromDateParam });
							setTimeout(() => {
								mutate1ObjectInCore('dataFilters', {
									lastRowId: 0,
									reloadSubDataFilters: true,
								});
								mutate1ObjectInCore('datasource', {
									reload: true,
								});
							}, 400);
						}}
					/>
				)}
				{/* TO DATE PICKER */}
				{filterParams?.toDateParam && !filterParams?.fromDateParam && (
					<DatePicker
						style={{ marginTop: '10px' }}
						placeholder='Hasta'
						value={dataFilterUserParams.toDateParam}
						onChange={toDateParam => {
							mutateUserParams({ toDateParam });
							setTimeout(() => {
								mutate1ObjectInCore('dataFilters', {
									lastRowId: 0,
									reloadSubDataFilters: true,
								});
								mutate1ObjectInCore('datasource', {
									reload: true,
								});
							}, 400);
						}}
					/>
				)}
			</Section>
		);
	},
);

const mapStateToProps = state => ({
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	selectedDataFilterId: selectSelectedDataFilterId(state),
	isFetching: selectDataFiltersIsFetching(state),
	dataFilters: selectDataFilters(state),
	dataFilterUserParams: selectDataFilterUserParams(state),
});
const actions = {
	getMyDatasourceAccess,
	mutate1ObjectInCore,
	mutateSubDataFilter,
	mutateUserParams,
	resetCustomFilterPropsInDataFilter,
	resetDataFilters,
};

export default connect(mapStateToProps, actions)(_DatasourceAccessPicker);
