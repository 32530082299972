import {
	FormComponentProcess,
	XlsxScrapingProcess,
	ReplacementValueProcess,
	FieldAnchorProcess,
} from './Process';

export default class XlsxOrderScraping {
	constructor(xlsxOrders, fields, formComponentValues) {
		this.xlsxOrders = xlsxOrders;
		this.fields = fields;
		this.formComponentValues = formComponentValues;
		this.odtIdField = this.fields.find(f => f.id === 'odtId');
	}

	filterEmptyRows = order => !this.odtIdField || order[this.odtIdField.id];

	async run() {
		return this.xlsxOrders
			.map(order => new XlsxScrapingProcess(order, this.fields).run())
			.map(order =>
				new FormComponentProcess(
					order,
					this.fields,
					this.formComponentValues,
				).run(),
			)
			.map(order => new FieldAnchorProcess(order, this.fields).run())
			.map(order => new ReplacementValueProcess(order, this.fields).run())
			.filter(this.filterEmptyRows);
	}
}
