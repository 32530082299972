// Libs
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
// Utils
import useOrderReportsApi from './useOrderReportsApi';
// Context
import { ModalBuilderContext } from 'core/context';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Components
import { ReportsIllustration } from 'components/Illustrations';
import {
	Title,
	Wrapper,
	CloseIcon,
	LogoIcon,
	Modal,
	ReportCard,
} from 'components';
import MergedPDFReport from 'components/DataFieldManager/MergedPDFReport/MergedPDFReportContext';

const OrderReports = ({
	// Actions
	sendToast,
}) => {
	const { payload: modalPayload, cleanModal } = useContext(ModalBuilderContext);
	const { selectedOTDId, selectedODTId } = modalPayload;

	const [reports, setReports] = useState([]);
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		let isMounted = true;
		if (!selectedOTDId) return;

		setIsFetching(true);
		useOrderReportsApi({ otdId: selectedOTDId }).then(reports => {
			if (!isMounted) return;
			if (reports && reports.length) {
				setReports(current => [...current, ...reports]);
			}
			setIsFetching(false);
		});

		return () => {
			isMounted = false;
		};
	}, [selectedOTDId]);

	return (
		<Modal
			title={null}
			closable={false}
			centered={false}
			visible={true}
			onCancel={cleanModal}
			footer={null}
			isSpinning={isFetching}
			sizeSpinning='large'
			delaySpinning={50}
			tipSpinning='Un momento porfavor...'
		>
			<Wrapper
				padding='16px'
				flexDirection='column'
				width='100%'
				minHeight='300px'
				mediumBackground
				position='relative'
			>
				<Wrapper padding='0' position='absolute' right='10px' top='7px'>
					<CloseIcon button onClick={cleanModal} />
				</Wrapper>
				<Wrapper padding='0' flexDirection='column'>
					<ReportsIllustration />
					<Title
						textAlign='center'
						display='inline-block'
						margin='16px 0 20px 0'
					>
						Estos son los reportes de las tareas realizadas en la orden
					</Title>
				</Wrapper>
				<Wrapper
					padding={isFetching ? '10px' : '0'}
					width='100%'
					flexDirection='column'
					overflow='auto'
					maxHeight='300px'
					highBackground
				>
					{isFetching ? (
						<LogoIcon spin={true} />
					) : (
						<>
							{reports.map((report, idx) => (
								<ReportCard
									key={idx}
									report={report}
									otdId={selectedOTDId}
									odtId={selectedODTId}
									sendToast={sendToast}
								/>
							))}
							<MergedPDFReport
								resourceId={selectedOTDId}
								nameButton={'Servicio consolidado'}
								style={'postCard'}
							/>
							<MergedPDFReport
								resourceId={selectedOTDId}
								nameButton={'Sitio consolidado'}
								typeMergeButton='contractMergedReports'
								style={'postCard'}
							/>
						</>
					)}
				</Wrapper>
			</Wrapper>
		</Modal>
	);
};

const actions = {
	sendToast,
};

export default connect(null, actions)(OrderReports);
