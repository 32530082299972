// Libs
import React from 'react';
import { connect } from 'react-redux';
// Utils
import { debounce } from 'utils/libs';
import { GlobalUtils } from 'utils';
import MODULE_KEYWORDS from 'components/Modules/keywords';
// Actions
import {
	getEveryDataFromProcessedSQL,
	mutate1Object as mutate1ObjectInCore,
	mutateSubDataFilter,
	mutateUserParams,
	resetUserParams,
} from 'core/actions';
// Selectors
import {
	selectSelectedProjectId,
	selectSelectedOrderTargetFilterId,
	selectSelectedServiceIds,
	selectSelectedDataFilterId,
	selectDataFilters,
	selectSubDataFilters,
	selectDataFilterUserParams,
	selectDataFiltersIsFetching,
	selectReloadSubDataFilters,
} from 'core/selectors';
// Components
import {
	Wrapper,
	Tooltip,
	Caption,
	RadioV2,
	CheckboxV2,
	LogoIcon,
	Section,
	SectionTitle,
	Span,
} from 'components';

const styles = {
	container: () => ({
		padding: '0',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
	}),
	item: () => ({
		padding: '0',
		margin: '0 0 5px 0',
	}),
};

const SubDataFilterOption = React.memo(
	({
		filterItem,
		multiselect,
		selectedFilterItemIds,
		selectedAll,
		onClick,
	}) => {
		const isSelected = React.useMemo(
			() =>
				(filterItem.id === 'all' && selectedAll) ||
				selectedFilterItemIds.findIndex(sId => sId === filterItem.id) !== -1,
			[selectedFilterItemIds, selectedAll],
		);

		return (
			<Wrapper {...styles.item()}>
				<Tooltip title={filterItem.description} placement='right'>
					<Wrapper
						padding='0'
						width='250px'
						height='20px'
						justifyContent='stretch'
						alignItems='center'
						overflow='hidden'
						cursor='pointer'
						onClick={() => onClick(filterItem.id)}
					>
						{multiselect ? (
							<CheckboxV2 isSelected={isSelected} />
						) : (
							<RadioV2 isSelected={isSelected} />
						)}
						<Span width='100%' hoverable ellipsis selected={isSelected}>
							{filterItem.name}
						</Span>
					</Wrapper>
				</Tooltip>
			</Wrapper>
		);
	},
);

/**
 * - Sub data filter item to show in list
 * @param {Object} subDataFilterItem - Filter item unique object
 * @param {Object[]} subDataFilterItem.data - Array of database items, generated from custom sql in master.module_setted_filters.sql
 * @param {string} subDataFilterItem.title - Title to show
 * @param {string} subDataFilterItem.state - State from individual item
 * @param {string} subDataFilterItem.param - Unique Param key to identify this filter item
 * @param {boolean} subDataFilterItem.multiselect - Allow multiselect option
 *
 * - User data inputs, eg: {fromDateParam, toDateParam, expertParam, supervisorParam}
 * @param {Object} dataFilterUserParams - Data filter main object from user inputs, this data object is dynamic
 * @param {Object[]} selectedFilterItemIds - Selected item ids from subDataFilterItem.data
 */
const SubDataFilterItem = React.memo(
	({
		isReload,
		setIsReload,
		selectedDataFilterId,
		dataFilterIsFetching,
		dataFilterUserParams,
		dataFilterItem,
		subDataFilterItem,
		selectedProjectId,
		selectedOrderTargetFilterId,
		selectedServiceIds,
		mutate1ObjectInCore,
		mutateSubDataFilter,
		mutateUserParams,
		getEveryDataFromProcessedSQL,
	}) => {
		const selectedFilterItemIds = React.useMemo(
			() =>
				GlobalUtils.checkArray(dataFilterUserParams[subDataFilterItem.param]),
			[dataFilterUserParams[subDataFilterItem.param]],
		);

		const items = React.useMemo(
			() => GlobalUtils.checkArray(subDataFilterItem.data),
			[subDataFilterItem.data],
		);

		// Must fetch data
		const getDebouncedParamFilters = React.useCallback(
			debounce(getEveryDataFromProcessedSQL, 200),
			[],
		);

		React.useEffect(() => {
			if (isReload && selectedDataFilterId && !dataFilterIsFetching) {
				setIsReload(false);
				getDebouncedParamFilters({
					selectedDataFilterId,
					subDataFilterId: subDataFilterItem.id,
					limit: dataFilterItem?.config?.limit,
					userParams: {
						...dataFilterUserParams,
						selectedProjectId,
						selectedOrderTargetFilterId,
						selectedServiceIds,
					},
					onLoading: function* () {
						mutateSubDataFilter({
							selectedDataFilterId,
							subDataFilterId: subDataFilterItem.id,
							filterProps: { state: MODULE_KEYWORDS.STATE.LOADING },
						});
					},
					onSuccess: function* ({ payload = {} }) {
						const { data } = payload;
						if (!Array.isArray(data)) return;
						mutateSubDataFilter({
							selectedDataFilterId,
							subDataFilterId: subDataFilterItem.id,
							filterProps: {
								state: MODULE_KEYWORDS.STATE.SUCCESS,
								data,
							},
						});
						if (data.length === 0) return;
						mutateUserParams({
							[subDataFilterItem.param]: subDataFilterItem.multiselect
								? data.map(i => i.id)
								: [data[0].id],
						});
					},
					onError: function* () {
						mutateSubDataFilter({
							selectedDataFilterId,
							subDataFilterId: subDataFilterItem.id,
							filterProps: {
								state: MODULE_KEYWORDS.STATE.ERROR,
							},
						});
					},
				});
			}
		}, [isReload, selectedDataFilterId, dataFilterIsFetching]);

		// Handle see more
		const [seeMore, setSeeMore] = React.useState('hide'); // hide, show, selected, collapsed
		React.useEffect(() => {
			if (items.length <= 3) setSeeMore('hide');
			else if (items.length > 3 && seeMore === 'hide') {
				setSeeMore('show');
			}
		}, [seeMore, items]);

		// Filter with see more
		const filteredItems = React.useMemo(() => {
			const filter = () => {
				if (items.length > 3) {
					if (seeMore === 'selected') return items;
					if (seeMore === 'collapsed') setSeeMore('show');
					return items.filter((p, idx) => idx <= 2);
				}
				return items;
			};

			if (items.length === 0) return filter();

			const _filteredItems = subDataFilterItem.multiselect
				? [
						{
							id: 'all',
							name: 'Todos',
						},
				  ]
				: [];

			return _filteredItems.concat(filter());
		}, [seeMore, items]);

		// Is selected all?
		const selectedAll = React.useMemo(
			() => selectedFilterItemIds.length === items.length,
			[selectedFilterItemIds, items],
		);

		const onClickSubDataFilterOption = React.useCallback(
			itemId => {
				if (subDataFilterItem.multiselect && itemId === 'all') {
					var updatedValueUserParam = selectedAll ? [] : items.map(i => i.id);
				} else {
					const isSelected =
						selectedFilterItemIds.findIndex(sId => sId === itemId) !== -1;
					if (!isSelected) {
						var updatedValueUserParam = subDataFilterItem.multiselect
							? [...selectedFilterItemIds, itemId]
							: [itemId];
					} else {
						var updatedValueUserParam = selectedFilterItemIds.filter(
							sId => sId !== itemId,
						);
					}
				}
				mutate1ObjectInCore('dataFilters', {
					lastRowId: 0,
				});
				mutate1ObjectInCore('datasource', {
					reload: true,
				});
				mutateUserParams({
					[subDataFilterItem.param]: updatedValueUserParam,
				});
			},
			[
				items,
				selectedFilterItemIds,
				subDataFilterItem,
				dataFilterUserParams,
				selectedAll,
			],
		);

		return (
			<Section>
				<SectionTitle
					empty={filteredItems.length === 0}
					title={subDataFilterItem.title}
				/>
				<Wrapper {...styles.container()}>
					{subDataFilterItem.state === MODULE_KEYWORDS.STATE.LOADING && (
						<LogoIcon spin={true} />
					)}
					{subDataFilterItem.state !== MODULE_KEYWORDS.STATE.LOADING &&
						filteredItems.map((filterItem, idx) => (
							<div key={idx}>
								<SubDataFilterOption
									filterItem={filterItem}
									multiselect={subDataFilterItem.multiselect}
									selectedFilterItemIds={selectedFilterItemIds}
									selectedAll={selectedAll}
									onClick={onClickSubDataFilterOption}
								/>
							</div>
						))}

					{seeMore === 'show' && seeMore !== 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('selected')}
						>
							Ver más
						</Caption>
					)}
					{seeMore === 'selected' && (
						<Caption
							emphasis
							hoverable
							margin='10px 0 0 0'
							onClick={() => setSeeMore('collapsed')}
						>
							Ver menos
						</Caption>
					)}
				</Wrapper>
			</Section>
		);
	},
);

const ParamFilterPicker = ({
	// State
	selectedProjectId,
	selectedOrderTargetFilterId,
	selectedServiceIds,
	selectedDataFilterId,
	isReloadSubDataFilters,
	dataFilters,
	subDataFilters,
	dataFilterIsFetching,
	dataFilterUserParams,
	// Actions
	mutate1ObjectInCore,
	mutateSubDataFilter,
	mutateUserParams,
	getEveryDataFromProcessedSQL,
	resetUserParams,
}) => {
	const [isReload, setIsReload] = React.useState();

	// Data filter config
	const dataFilterItem = React.useMemo(
		() => dataFilters.find(df => df.id === selectedDataFilterId),
		[selectedDataFilterId, dataFilters],
	);

	React.useEffect(() => {
		if (!isReloadSubDataFilters) return;

		mutate1ObjectInCore('dataFilters', { reloadSubDataFilters: false });
		resetUserParams();
		return () => {
			setIsReload(true);
		};
	}, [isReloadSubDataFilters]);

	if (subDataFilters.length === 0) return null;

	return subDataFilters.map((subDataFilterItem, idx) => (
		<div key={idx}>
			<SubDataFilterItem
				isReload={isReload}
				setIsReload={setIsReload}
				selectedDataFilterId={selectedDataFilterId}
				dataFilterIsFetching={dataFilterIsFetching}
				dataFilterUserParams={dataFilterUserParams}
				dataFilterItem={dataFilterItem}
				subDataFilterItem={subDataFilterItem}
				selectedProjectId={selectedProjectId}
				selectedOrderTargetFilterId={selectedOrderTargetFilterId}
				selectedServiceIds={selectedServiceIds}
				mutate1ObjectInCore={mutate1ObjectInCore}
				mutateSubDataFilter={mutateSubDataFilter}
				mutateUserParams={mutateUserParams}
				getEveryDataFromProcessedSQL={getEveryDataFromProcessedSQL}
			/>
		</div>
	));
};

const mapStateToProps = state => ({
	selectedProjectId: selectSelectedProjectId(state),
	selectedOrderTargetFilterId: selectSelectedOrderTargetFilterId(state),
	selectedServiceIds: selectSelectedServiceIds(state),
	selectedDataFilterId: selectSelectedDataFilterId(state),
	isReloadSubDataFilters: selectReloadSubDataFilters(state),
	dataFilters: selectDataFilters(state),
	subDataFilters: selectSubDataFilters(state),
	dataFilterIsFetching: selectDataFiltersIsFetching(state),
	dataFilterUserParams: selectDataFilterUserParams(state),
});
const actions = {
	getEveryDataFromProcessedSQL,
	mutate1ObjectInCore,
	mutateSubDataFilter,
	mutateUserParams,
	resetUserParams,
};
export default connect(mapStateToProps, actions)(ParamFilterPicker);
