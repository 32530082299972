// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/libs/dateFormats';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import {
	Wrapper,
	ShowMoreText,
	Acronym,
	Subtitle,
	Span,
	PointTextIcon,
	CopyToClipboard,
} from 'components';
// Styles
import styles from './Supervisor.module.css';

export const DataInfo = ({ data }) => (
	<CopyToClipboard value={data}>
		<Span hoverable ellipsis>
			{data}
		</Span>
	</CopyToClipboard>
);

const PostInfo = ({ record }) => {
	const profile = useSelector(selectProfile);

	return (
		<div className={styles.postInfo}>
			<Acronym text={record.userName} />
			<div style={{ paddingLeft: '10px' }}>
				<Subtitle>{record.userName}</Subtitle>
				<Wrapper flexWrap={'wrap'} padding='0' alignItems='baseline'>
					<DataInfo data={record.odtId} />
					<PointTextIcon />
					<DataInfo data={record.clientIdc} />
					{String(record.clientName).toLowerCase() !==
						String(record.clientIdc).toLowerCase() && (
						<>
							<PointTextIcon />
							<DataInfo data={record.clientName} />
						</>
					)}
					<PointTextIcon />
					<DataInfo data={record.serviceName} />
					<PointTextIcon />
					<Span disabled>
						{formatDate(record.createdAt, profile, 'ddd DD/MM')}
					</Span>
				</Wrapper>
			</div>
		</div>
	);
};

const UserComment = ({ text, showLines = 1 }) => {
	if (!text) return null;

	const limitText = 430;
	const ellipsis = text.length > limitText ? '...' : '';

	return (
		<ShowMoreText lines={showLines} onClick={() => console.log()}>
			<CopyToClipboard value={text}>
				<Span hoverable disabled>
					{`${text.slice(0, limitText)}${ellipsis}`}
				</Span>
			</CopyToClipboard>
		</ShowMoreText>
	);
};

export { PostInfo, UserComment };
