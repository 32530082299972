// Libs
import React, { useState, useEffect, Suspense } from 'react';
// Utils
import { getReactionDetails } from './Reactions.utils';
// Config
import ReactionBuilderConfig from './reaction.config';
// Components
import {
	LogoIcon,
	Popover,
	SubCaption,
	AntButton,
	Span,
	Wrapper,
	Tooltip,
	Caption,
	FullStarIcon,
} from 'components';
// Styles
import styles from './Reactions.module.css';
import { getHumanize } from 'utils/libs/dateFormats';

const Reaction = ({ reactionKey, count, isLoading, disabled, onClick }) => {
	const ButtonIcon = ReactionBuilderConfig.getReactionIcon(reactionKey);
	if (!ButtonIcon) return null;

	return (
		<AntButton disabled={disabled} onClick={onClick} type='link'>
			{!isLoading ? (
				<div className={styles.buttonContentWrapper}>
					{count && <SubCaption disabled>{count}</SubCaption>}
					<Suspense fallback={<LogoIcon spin={true} />}>
						<ButtonIcon />
					</Suspense>
				</div>
			) : (
				<LogoIcon spin={true} />
			)}
		</AntButton>
	);
};

const ReactionDetails = ({ reactionKey, resourceKey, resourceId }) => {
	const [state, _setState] = useState({
		isLoading: false,
		reactions: [],
	});
	const setState = newProps => _setState(prev => ({ ...prev, ...newProps }));

	useEffect(() => {
		setState({ isLoading: true });
		getReactionDetails({ reactionKey, resourceKey, resourceId })
			.then(reactionDetails =>
				setState({
					isLoading: false,
					reactions: reactionDetails,
				}),
			)
			.catch(err => setState({ isLoading: false }));
	}, []);

	const updatedAtWidth = '90px';

	return (
		<Wrapper maxWidth='280px' padding='0' flexDirection='column'>
			{state.isLoading ? (
				<LogoIcon spin />
			) : (
				state.reactions.map(({ userName, rating, updatedAt }) => (
					<Wrapper
						width='100%'
						padding='0'
						alignItems='center'
						justifyContent='space-between'
					>
						<Wrapper
							padding='0'
							margin='0 10px 0 0'
							maxWidth={`calc(100% - ${updatedAtWidth})`}
						>
							<Span ellipsis margin='0 5px 0 0'>
								{userName}
							</Span>
							<FullStarIcon size='small' />
							<Caption>{Math.round(rating)}</Caption>
						</Wrapper>
						<Caption textAlign='right'>
							{getHumanize({ date: updatedAt })}
						</Caption>
					</Wrapper>
				))
			)}
		</Wrapper>
	);
};

const ReactionButton = ({
	reactionKey,
	isLoading,
	disabled,
	count,
	resourceKey,
	resourceId,
	onClick,
}) => {
	const hasReactions = Number(count) > 0;

	if (!hasReactions)
		return (
			<Reaction
				reactionKey={reactionKey}
				count={count}
				isLoading={isLoading}
				disabled={disabled}
				onClick={onClick}
			/>
		);

	return (
		<Tooltip
			trigger='hover'
			placement='bottom'
			overlay={
				<ReactionDetails
					reactionKey={reactionKey}
					resourceKey={resourceKey}
					resourceId={resourceId}
				/>
			}
		>
			<div>
				<Reaction
					reactionKey={reactionKey}
					count={count}
					isLoading={isLoading}
					disabled={disabled}
					onClick={onClick}
				/>
			</div>
		</Tooltip>
	);
};

export default ReactionButton;
