// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { mutate1Object as mutate1ObjectInCore } from '../actions';
// Selectors
import { selectActionForm } from '../selectors';
// Hooks
import { useForm } from 'hooks';
// Components
import { Modal, Wrapper, LogoIcon } from 'components';
// Config
import FormBuilderConfig from './form.config';
import DefaultForm from './DefaultForm';

const Loading = () => (
	<Wrapper
		width='100%'
		padding='0'
		height='200px'
		justifyContent='center'
		alignItems='center'
	>
		<LogoIcon spin={true} size='m' />
	</Wrapper>
);

const FormBuilder = ({ isMobile }) => {
	const dispatch = useDispatch();

	// Select from datasource
	const actionForm = useSelector(selectActionForm);
	const { form, values, setValues, submitForm, getProcessedComponentSqlData } =
		useForm(actionForm);

	// Get CustomForm by key or DefaultForm
	const Form = FormBuilderConfig.getFormReactNode(form.key) || DefaultForm;
	if (!Form) return null;

	if (!actionForm.isOpen) return null;

	return (
		<Modal
			title={form.title}
			closable={false}
			centered={false}
			visible={actionForm.isOpen}
			footer={null}
			onCancel={() =>
				dispatch(mutate1ObjectInCore('actionForm', { isOpen: false }))
			}
		>
			<Wrapper width='100%' justifyContent='center' padding='0 16px 16px 16px'>
				{form.isFetching ? (
					<Loading />
				) : (
					<Form
						isMobile={isMobile}
						form={form}
						values={values}
						actionPayload={actionForm.payload}
						empty={!form.components.length}
						setValues={setValues}
						submitForm={submitForm}
						getProcessedComponentSqlData={getProcessedComponentSqlData}
					/>
				)}
			</Wrapper>
		</Modal>
	);
};

export default FormBuilder;
