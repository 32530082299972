import { read, utils } from 'xlsx';

function validateInput(event) {
	if (!event.target || !event.target.files || event.target.files.length === 0) {
		event.target.value = '';
		throw new Error(
			'No se encontró ningún archivo. Por favor vuelva a intentarlo',
		);
	}
	return event.target.files;
}

function getExcelFormat(file) {
	const fileReader = new FileReader();

	return new Promise((resolve, reject) => {
		fileReader.onerror = () => {
			fileReader.abort();
			reject(
				new DOMException(
					`Tuve un problema al procesar el archivo. Por favor intentelo nuevamente`,
				),
			);
		};

		fileReader.onload = e => {
			resolve(fileReader.result);
		};

		fileReader.readAsBinaryString(file);
	});
}

function parseExcelToJson(excelFile) {
	try {
		const workbook = read(excelFile, { type: 'binary' });
		const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
		return utils.sheet_to_json(firstSheet, { headers: 1 }); //TODO: Esta línea está durando mucho (26s aprox), se debe revisar cómo se puede mejorar
	} catch (err) {
		throw new Error(
			'Tuve un problema al procesar el archivo. Por favor intentelo nuevamente',
		);
	}
}

export function readExcel(event, latestFile) {
	event.preventDefault();
	return new Promise(async (resolve, reject) => {
		if (!event.target.value) {
			resolve(null);
		}
		try {
			const files = validateInput(event);

			if (latestFile) {
				const fileName = files[files.length - 1].name;
				const file = files.item(files.length - 1);
				const excelFile = await getExcelFormat(file);
				const jsonFile = parseExcelToJson(excelFile);
				resolve({ fileName, jsonFile });
			} else {
				const jsonFiles = [];
				for (let i = 0; i < files.length; i++) {
					const file = files.item(i);
					const excelFile = await getExcelFormat(file);
					const jsonFile = parseExcelToJson(excelFile);
					jsonFiles.push(jsonFile);
				}
				resolve(jsonFiles);
			}
		} catch (err) {
			reject(err);
		}
	});
}

export function readExcelFile(file) {
	return new Promise(async (resolve, reject) => {
		try {
			const excelFile = await getExcelFormat(file);
			const jsonFile = parseExcelToJson(excelFile);
			resolve(jsonFile);
		} catch (err) {
			reject(null);
		}
	});
}
