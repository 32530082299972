class Field {
	constructor(field, rowData, metadata, setReload) {
		if (new.target === Field) {
			throw new Error('Cannot instantiate abstract class');
		}
		this.id = field.id;
		this.name = field.name;
		this.type = field.type;
		this.uiValueProp = field.uiValueProp;
		this.setZoneField(field, rowData, metadata, setReload);
	}

	setZoneField(field, rowData, metadata, setReload) {
		if (
			field.id !== 'zoneId' &&
			field.id !== 'contractZoneId' &&
			field.id !== 'orderZoneId'
		)
			return;

		this.canUpdateAddress =
			!!field.onChange ||
			rowData?.eventProps?.changeAddress ||
			metadata?.eventProps?.changeAddress;
		this.setReload = setReload;
	}
}

export class StandardField extends Field {
	constructor({ field, rowData, metadata, setReload }) {
		super(field, rowData, metadata, setReload);
		this.uiValuePropLevel = field.uiValuePropLevel;
		this.filterable = field.filterable;
		this.unselectable = field.unselectable;
		this.width = field.width;
		this.show = field.show;
		this.onChange = field.onChange;
	}
}

export class OverviewField extends Field {
	constructor({ field, rowData, metadata, setReload }) {
		super(field, rowData, metadata, setReload);
		this.value = field.value;
		this.uiValue = field.uiValue;
		this.main = field.main;
	}
}
