// Libs
import React from 'react';
// Utils
import { ImportUtils } from 'utils/Orders/ImportProcess';
// Components
import { StandardTableScreen } from 'core/ScreenBuilder/screens/StandardTableScreen';

const TablePreview = ({ previewOrders, fields, fieldActions }) => {
	const datasourceFields = new ImportUtils(
		previewOrders,
		fields.concat({
			id: 'delete',
			name: 'Eliminar',
			width: 100,
			filterable: false,
		}),
	).getFormattedFields(fieldActions.ids);

	return (
		<StandardTableScreen
			isLoading={false}
			dataFilterLastRowId={undefined}
			datasourceFields={datasourceFields}
			datasourcePaging={{ totalLastRowId: 0 }}
			datasource={previewOrders}
			fieldActionTypes={fieldActions.types}
		/>
	);
};

export default TablePreview;
