// Libs
import axios from 'axios';
// Services
import AuthService from 'utils/libs/auth/AuthService';
// Utils
import API from 'utils/constants/api';
// Services
import SentryService from '../sentryService';

const auth = new AuthService();

/**
 * Welcome to ApiServiceConfig
 * Max steps to implement new Action: 3
 */

const METHODS = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	DELETE: 'delete',
};

/**
 * Step 1: Add your new authorized endpoint key
 */
const ENDPOINT_KEYS = {
	GENERATE_REPORT: 'GENERATE_REPORT',
	GET_COMMENTS: 'GET_COMMENTS',
	SUBMIT_COMMENT: 'SUBMIT_COMMENT',
	GET_ROLES: 'GET_ROLES',
	GET_FEED_DISPLAY_OPTIONS: 'GET_FEED_DISPLAY_OPTIONS',
	GET_REACTIONS: 'GET_REACTIONS',
	GET_REACTION_DETAILS: 'GET_REACTION_DETAILS',
	SUBMIT_REACTION: 'SUBMIT_REACTION',
	GET_ORDER_REPORTS: 'GET_ORDER_REPORTS',
	// <--HERE
};

/**
 * Step 2: Add your new endpoint headers
 */
const ENDPOINT_HEADERS = {
	[ENDPOINT_KEYS.GENERATE_REPORT]: {
		method: METHODS.POST,
	},
	[ENDPOINT_KEYS.GET_COMMENTS]: {
		method: METHODS.GET,
	},
	[ENDPOINT_KEYS.SUBMIT_COMMENT]: {
		method: METHODS.POST,
	},
	[ENDPOINT_KEYS.GET_ROLES]: {
		method: METHODS.GET,
	},
	[ENDPOINT_KEYS.GET_FEED_DISPLAY_OPTIONS]: {
		method: METHODS.GET,
	},
	[ENDPOINT_KEYS.GET_REACTIONS]: {
		method: METHODS.GET,
	},
	[ENDPOINT_KEYS.GET_REACTION_DETAILS]: {
		method: METHODS.GET,
	},
	[ENDPOINT_KEYS.SUBMIT_REACTION]: {
		method: METHODS.POST,
	},
	[ENDPOINT_KEYS.GET_ORDER_REPORTS]: {
		method: METHODS.GET,
	},
	// <--HERE
};

class ApiServiceConfig {
	static getEndpointKeys() {
		return ENDPOINT_KEYS;
	}

	static getEndpointHeaders() {
		return ENDPOINT_HEADERS;
	}

	static getMethods() {
		return METHODS;
	}

	/**
	 * Step 3: Add your new endpoint
	 * @param {string} endpoint - Any ENDPOINT_KEYS prop
	 * @param {Object} reqParams - Custom props to inject on request params
	 * @usage // Props usage eg: [ENDPOINT_KEYS.GENERATE_REPORT]: `/collector_values/generateReportFromAuditedOrder/${reqParams.myParam}`
	 * @returns Fully built endpoint
	 */
	static authorizedEndpoints = (endpoint, reqParams = {}) => {
		return {
			[ENDPOINT_KEYS.GENERATE_REPORT]: `/collector_values/generateReportFromAuditedOrder`,
			[ENDPOINT_KEYS.GET_COMMENTS]: `/commentManager/v1/${reqParams.resourceKey}/${reqParams.resourceId}/${reqParams.parentId}/${reqParams.lastRowId}/${reqParams.limit}`,
			[ENDPOINT_KEYS.SUBMIT_COMMENT]: `/commentManager/v1`,
			[ENDPOINT_KEYS.GET_ROLES]: `/users/getRolesV2`,
			[ENDPOINT_KEYS.GET_FEED_DISPLAY_OPTIONS]: `/feedManager/v1/displayOptions/${reqParams.selectedDataFilterId}`,
			[ENDPOINT_KEYS.GET_REACTIONS]: `/reactionManager/v1/${reqParams.resourceKey}/${reqParams.resourceId}`,
			[ENDPOINT_KEYS.GET_REACTION_DETAILS]: `/reactionManager/v1/${reqParams.resourceKey}/${reqParams.resourceId}/${reqParams.reactionKey}/details`,
			[ENDPOINT_KEYS.SUBMIT_REACTION]: `/reactionManager/v1`,
			[ENDPOINT_KEYS.GET_ORDER_REPORTS]: `/orders/reports/${reqParams.otdId}`,
			// <--HERE
		}[endpoint];
	};

	static getInstance() {
		return axios.create({
			baseURL: API.DOMAIN,
		});
	}

	static checkBodyParams(method, bodyParams) {
		if (method !== METHODS.POST && method !== METHODS.PUT) return;
		try {
			return JSON.parse(JSON.stringify(bodyParams));
		} catch (err) {
			SentryService.sendError(err);
		}
	}

	static buildRequest({ authorizedEndpoint, headers, bodyParams }) {
		return {
			method: headers.method,
			url: authorizedEndpoint,
			data: this.checkBodyParams(headers.method, bodyParams),
			headers: {
				Authorization: `Bearer ${auth.getToken()}`,
			},
		};
	}
}

export default ApiServiceConfig;
