// Libs
import React from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Utils
import TableUtils from './utils';
// Hooks
import { useTheme } from 'hooks';
// Components
import Wrapper from 'components/Wrapper/Wrapper';
import TableVirtualized from 'components/TableVirtualized';
import { LogoIcon } from 'components/Icons';
import RowRenderer from './RowRenderer';
import HeaderRenderer from './HeaderRenderer';
import CellRenderer from './CellRenderer';
import styled from 'styled-components';
import { selectSelectedDataSourceConfig } from 'core/selectors';

const ContainerTable = styled.div`
	overflow-x: ${props => (props.notHorizontalScroll ? 'hidden' : 'auto')};
	height: calc(100vh - 56px);
	&::-webkit-scrollbar {
		width: 5px;
		height: 10px;
	}
	overflow-y: hidden;
`;

const { Table, Column, Cell } = TableVirtualized;

const StandardTableScreen = ({
	elementKey,
	screenKey,
	isMobile,
	isLoading,
	dataFilterLastRowId,
	datasourceFields,
	datasourcePaging,
	datasource,
	fieldActionTypes,
	setLastRowId,
	reloadDatasource,
}) => {
	const config = useSelector(selectSelectedDataSourceConfig);
	const profile = useSelector(selectProfile);
	const { theme, isDarkTheme } = useTheme();
	const [state, setState] = React.useState({
		selected: {
			items: [],
		},
		filters: {
			/**
			 * [dataKey]: {
			 *  checkedList: [],
			 *  indeterminate: false,
			 *  checkedAll: false,
			 *  inputSearch: undefined
			 * }
			 */
		},
		sort: {
			dataKey: undefined,
			direction: 'asc',
		},
	});

	const lastRowId = React.useMemo(() => datasource.at(-1)?.id, [datasource]);
	const isTotalLastRow = React.useMemo(
		() => Number(lastRowId) === Number(datasourcePaging.totalLastRowId),
		[lastRowId],
	);

	const onRowsRendered = ({ overscanStopIndex, stopIndex }) => {
		if (
			overscanStopIndex + 1 === datasource.length &&
			!isTotalLastRow &&
			setLastRowId
		) {
			setLastRowId(lastRowId);
			if (
				dataFilterLastRowId !== undefined &&
				lastRowId !== dataFilterLastRowId &&
				reloadDatasource
			)
				reloadDatasource();
		}
	};

	const filteredDatasource = React.useMemo(
		() => TableUtils.filter(datasource, state.filters),
		[datasource, state.filters],
	);

	// TODO: Terminar la funcionalidad de sorting
	// filteredDatasource = React.useMemo(
	//   () =>
	//     TableUtils.sort({
	//       data: filteredDatasource,
	//       fields: datasourceFields,
	//       dataKey: state.sort.dataKey,
	//       dateFormat: profile.user.settings.date_format,
	//     }),
	//   [state.sort.dataKey, filteredDatasource]
	// );

	const totalWidth = datasourceFields.reduce(
		(sum, field) => sum + field.width,
		0,
	);
	return (
		<Wrapper width='100%' padding='0' position='relative'>
			<ContainerTable notHorizontalScroll={config.notHorizontalScroll}>
				<Table
					highBackground
					style={{
						backgroundColor: isDarkTheme
							? theme._primaryBackgroundColor
							: theme._secondaryBackgroundColor,
					}}
					width={
						config.notHorizontalScroll
							? document.documentElement.clientWidth
							: totalWidth
					}
					height={document.documentElement.clientHeight - 56}
					headerHeight={40}
					rowHeight={25}
					rowCount={filteredDatasource.length}
					rowGetter={({ index }) => filteredDatasource[index]}
					onRowsRendered={onRowsRendered}
					rowRenderer={props => (
						<RowRenderer
							{...props}
							rowKey={props.key}
							filteredDatasource={filteredDatasource}
							selected={state.selected}
							setState={setState}
						/>
					)}
				>
					{datasourceFields.map(field => (
						<Column
							key={field.id}
							dataKey={field.id}
							label={field.name}
							width={field.width}
							cellRenderer={props => (
								<CellRenderer
									{...props}
									Container={Cell}
									field={field}
									selected={state.selected}
									fieldActionTypes={fieldActionTypes}
									profile={profile}
									setState={setState}
								/>
							)}
							headerRenderer={props => (
								<HeaderRenderer
									{...props}
									field={field}
									datasource={datasource}
									filters={state.filters}
									dateFormat={profile.user.settings.date_format}
									setState={setState}
								/>
							)}
						/>
					))}
				</Table>
			</ContainerTable>

			{isLoading && dataFilterLastRowId > 0 && (
				<Wrapper padding='0' position='absolute' right='16px' bottom='16px'>
					<LogoIcon spin={true} size='m' />
				</Wrapper>
			)}
		</Wrapper>
	);
};

export default StandardTableScreen;
