// Libs
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
// Utils
import GENERAL from 'utils/constants/general';
// Selectors
import { selectDatasourceSingleData } from 'core/selectors';
// Context
import { ElementViewBuilderContext } from 'core/context';
// Hooks
import { useNearScreen } from 'hooks';
import useTheme from 'hooks/useTheme';
// Config
import { ScreenBuilderConfig } from 'core/ScreenBuilder';
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';
// Components
import { Wrapper } from 'components';
// Components
import { ProgressBar, Header } from './common';
import ActionsBox from '../ActionsBox';
import ImageGrid from '../ImageGrid';
// Styles
import styles from './PostV1.module.css';

const { RESOURCES } = GENERAL.ENV;

const Logo = ({ img }) => (
	<Wrapper
		overflow='hidden'
		height='fit-content'
		width='45%'
		maxWidth='75px'
		padding='0'
		borderRadius='3px'
		position='absolute'
		bottom='0'
		justifyContent='center'
		alignItems='center'
	>
		<img src={img} alt='' style={{ width: '100%' }} />
	</Wrapper>
);

const Photos = ({ record, rootObserverRef, gridView }) => {
	const { logo } = useSelector(selectDatasourceSingleData);
	const { setElementView } = useContext(ElementViewBuilderContext);
	const [isNearScreen, fromRef] = useNearScreen({
		root: rootObserverRef?.current,
		rootMargin: '200px',
	});

	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const handleOnClickPhotos = () => {
		setElementView({
			elementViewKey: elementViewKeys.SUPERVISOR_ELEMENT,
			elementId: record.id,
		});
	};

	const height = (() => {
		if (!gridView) return;

		if (record.userComment) {
			if (record.userComment.length >= 45) return '155px';

			return '175px';
		}

		return '195px';
	})();

	return (
		<Wrapper
			position='relative'
			padding='0'
			elementRef={fromRef}
			justifyContent
			height={height}
		>
			<ImageGrid
				gridView={gridView}
				sources={record.images}
				totalCount={record.totalImageCount}
				isNearScreen={isNearScreen}
				logo={logo}
				onClick={handleOnClickPhotos}
			/>
			<Logo img={logo} />
		</Wrapper>
	);
};

const PostV1 = ({ screenKey, record, rootObserverRef }) => {
	const { theme } = useTheme();
	const gridView =
		screenKey === ScreenBuilderConfig.authorizedScreenKeys().standardGridV1;

	const [reactionHandler, setReactionHandler] = useState({
		reactions: [],
		setted: false,
	});

	return (
		<div
			className={cx(styles.postWrapper, theme.secondaryBackgroundColor)}
			style={gridView ? { height: '500px' } : undefined}
		>
			<Header record={record} />
			<Photos
				record={record}
				rootObserverRef={rootObserverRef}
				gridView={gridView}
			/>

			<Wrapper
				backgroundColor={theme._secondaryBackgroundColor}
				bottom='0'
				position='absolute'
				flexDirection='column'
				padding='0'
				alignItems='flex-start'
				width='100%'
			>
				<ProgressBar record={record} />
				<ActionsBox
					resourceKey={RESOURCES.KEYS.PHOTO_REPORT}
					resourceId={record.id}
					odtId={record.odtId}
					reportCount={record.totalReportCount}
					commentCount={record.totalCommentCount}
					externalReactionHandler={{
						extReactions: reactionHandler.reactions,
						extSetted: reactionHandler.setted,
						setExtReactions: setReactionHandler,
					}}
				/>
			</Wrapper>
		</div>
	);
};

export default PostV1;
