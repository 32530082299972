// Libs
import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/libs/dateFormats';
import { ElementViewBuilderContext, ModalBuilderContext } from 'core/context';
import { ElementViewBuilderConfig } from 'core/ElementViewBuilder';
// Utils
import CollectorEcosystemAuthorization from 'components/Collector/Authorization';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Components
import { ModalBuilderConfig } from 'core/ModalBuilder';
import {
	Badge,
	DeleteIcon,
	Divider,
	MapIcon,
	MoreActions,
	Span,
	Title,
	Wrapper,
} from 'components';
import CollectorInventorySync from 'components/Collector/CollectorInventorySync';
import { UserComment, DataInfo } from '../../Shared';

const TitleWrapper = ({ clientName, profile, record, isMoreActions }) => {
	const { setElementView } = useContext(ElementViewBuilderContext);

	const modalKeys = ModalBuilderConfig.getAuthorizedModalKeys();
	const elementViewKeys =
		ElementViewBuilderConfig.getAuthorizedElementViewKeys();

	const { setModal } = useContext(ModalBuilderContext);
	const [_, setRerender] = useState(0);

	const handles = useRef({
		event: '',
		sync: {
			onClick: () => {},
		},
		deleteAuditedOTD: {
			onclick: () =>
				setModal(modalKeys.orderDeleteAuditedOTD, {
					selectedOTDId: record.id,
				}),
		},
	});

	const setHandleSync = ({ onClick, onEvent }) => {
		handles.current.sync.onClick = onClick;
		handles.current.event = onEvent;
		setRerender(prev => prev + 1);
	};

	const isAuth = new CollectorEcosystemAuthorization(
		profile,
	).checkDeleteOtdReport();

	const deleteComponent = isAuth && {
		title: 'Borrar reporte',
		onClick: handles.current.deleteAuditedOTD.onclick,
		color: 'red',
		icon: DeleteIcon,
	};
	const syncWarehouseComponent = (
		<CollectorInventorySync otdId={record.id} setHandleSync={setHandleSync} />
	);

	const syncWharehouse = {
		title: 'Sincronizar almacen',
		onClick: handles.current.sync.onClick,
		component: syncWarehouseComponent,
	};

	const handleOnClickMapButton = () => {
		setElementView({
			elementViewKey: elementViewKeys.MAP_ELEMENT,
			elementId: record.id,
		});
	};

	//  TODO: add notification for event to MoreActions
	const list = [
		{
			title: 'Mapa',
			onClick: handleOnClickMapButton,
			icon: MapIcon,
		},
		syncWharehouse,
		deleteComponent,
	];
	return (
		<Wrapper
			width='100%'
			justifyContent='space-between'
			alignItems='center'
			padding='0'
		>
			<Wrapper
				width='88%'
				padding='0'
				flexDirection='column'
				alignItems='flex-start'
				overflow='hidden'
			>
				<Title width='100%' ellipsis>
					{clientName}
				</Title>
				<Span disabled>
					{formatDate(record.createdAt, profile, 'ddd DD/MM')}
				</Span>
			</Wrapper>
			{isMoreActions && list.length && (
				<Wrapper padding='0' position='absolute' right='0' top='8px'>
					<Badge offset='5px' size='11px' event={handles.current.event}>
						<MoreActions list={list} />
					</Badge>
				</Wrapper>
			)}
		</Wrapper>
	);
};

const Field = ({ children }) => {
	return (
		<Wrapper gap='4px' padding='0' width='100%' justifyContent='space-between'>
			{children}
		</Wrapper>
	);
};

const Concept = ({ children }) => (
	<Span disabled width='100px'>
		{children}
	</Span>
);

const CardData = ({
	otdId,
	odtId,
	userName,
	clientIdc,
	contractNumber,
	serviceName,
}) => {
	return (
		<Wrapper
			padding='0 16px 0 0'
			margin='10px 0 0 0'
			flexDirection='column'
			alignItems='flex-start'
			width='100%'
		>
			<Field>
				<Concept>ID Reporte</Concept>
				<DataInfo data={otdId} />
			</Field>
			<Field>
				<Concept>ID Orden</Concept>
				<DataInfo data={odtId} />
			</Field>
			<Field>
				<Concept>ID Sitio</Concept>
				<DataInfo data={clientIdc} maxWidth='100%' />
			</Field>
			<Field>
				<Concept>ID Proyecto</Concept>
				<DataInfo data={contractNumber || ''} maxWidth='100%' />
			</Field>
			<Field>
				<Concept>Servicio</Concept>
				<DataInfo data={serviceName} maxWidth='100%' />
			</Field>
			<Field>
				<Concept>Tecnico</Concept>
				<DataInfo data={userName} maxWidth='100%' />
			</Field>
		</Wrapper>
	);
};

const Header = ({ record, isMoreActions = true }) => {
	const {
		id: otdId,
		userName,
		odtId,
		createdAt,
		clientIdc,
		clientName,
		contractNumber,
		serviceName,
	} = record;
	const profile = useSelector(selectProfile);

	return (
		<Wrapper width='100%' padding='16px 0px 0px 16px' flexDirection='column'>
			<TitleWrapper
				clientName={clientName}
				profile={profile}
				record={record}
				isMoreActions={isMoreActions}
			/>

			<CardData
				otdId={otdId}
				odtId={odtId}
				createdAt={createdAt}
				clientIdc={clientIdc}
				userName={userName}
				contractNumber={contractNumber}
				serviceName={serviceName}
			/>

			<Divider style={{ margin: '10px 0' }} width='290px' height='1px' />

			<UserComment text={record.userComment} />
		</Wrapper>
	);
};

export default Header;
