import { lazy } from 'react';

/**
 * Welcome to ElementViewBuilderConfig
 * Max steps to implement new ElementView: 4
 */

import KEYWORDS from 'core/keywords';

/**
 * Step 1: Import custom element view
 */
const { ROUTES } = KEYWORDS;
// <--HERE

/**
 * Step 2: Add custom element view keys
 */
const authorizedElementViewKeys = {
	SUPERVISOR_ELEMENT: `${ROUTES.FEED.PATH}/supervisor/:elementId`,
	CHART_ELEMENT: `${ROUTES.FEED.PATH}/analytics/:elementId`,
	ORDER_ELEMENT: `${ROUTES.FEED.PATH}/orders/:elementId`,
	MAP_ELEMENT: `${ROUTES.FEED.PATH}/map/:elementId`,
	INVENTORY_REQUEST_ELEMENT: `${ROUTES.FEED.PATH}/inventory.requests/:elementId`,
	// <--HERE
};

/**
 * Step 3: Add custom element view key value pairs
 */
const authorizedElementViewComponents = {
	[authorizedElementViewKeys.SUPERVISOR_ELEMENT]: lazy(
		() => import('./views/PhotoReportViewer/PhotoReportViewer'),
	),
	[authorizedElementViewKeys.CHART_ELEMENT]: lazy(
		() => import('./views/ChartViewer/ChartViewer'),
	),
	[authorizedElementViewKeys.ORDER_ELEMENT]: lazy(
		() => import('./views/OrderViewer/OrderViewer'),
	),
	[authorizedElementViewKeys.MAP_ELEMENT]: lazy(
		() => import('./views/MapViewer/MapViewer'),
	),
	[authorizedElementViewKeys.INVENTORY_REQUEST_ELEMENT]: lazy(
		() => import('./views/Inventory/RequestViewer'),
	),
	// <--HERE
};

/**
 * Step 4: Add custom element view key value pairs
 */
const authorizedElementViewConfig = {
	[authorizedElementViewKeys.SUPERVISOR_ELEMENT]: {
		maxWidth: '100%',
		placement: 'right',
		noOverflow: true,
	},
	[authorizedElementViewKeys.CHART_ELEMENT]: {
		maxWidth: '800px',
		placement: 'right',
		noOverflow: true,
	},
	[authorizedElementViewKeys.ORDER_ELEMENT]: {
		maxWidth: '700px',
		placement: 'right',
		noOverflow: false,
	},
	[authorizedElementViewKeys.MAP_ELEMENT]: {
		maxWidth: '100%',
		placement: 'left',
		noOverflow: false,
	},
	[authorizedElementViewKeys.INVENTORY_REQUEST_ELEMENT]: {
		maxWidth: '925px',
		placement: 'right',
		noOverflow: false,
	},
	// <--HERE
};

class ElementViewBuilderConfig {
	static getAuthorizedElementViewKeys() {
		return authorizedElementViewKeys;
	}

	static getAuthorizedElementViewReactNodes() {
		return authorizedElementViewComponents;
	}

	static getAuthorizedElementViewReactNode(elementViewKey) {
		return authorizedElementViewComponents[elementViewKey];
	}

	static getAuthorizedElementViewConfig(elementViewKey) {
		return authorizedElementViewConfig[elementViewKey] || {};
	}
}

export default ElementViewBuilderConfig;
