import { lazy } from 'react';
// Utils
import { GlobalUtils } from 'utils';

/**
 * Welcome to ElementBuilderConfig
 * Max steps to implement new Action: 3
 */

/**
 * Step 1: Import new Screen Picker Icon
 */
import {
	ListIcon,
	// <--HERE
} from 'components/Icons';

/**
 * Step 2: Add authorized element key
 */
const authorizedElementKeys = {
	spvrPostV1: 'spvrPostV1',
	spvrListV1: 'spvrListV1',
	ctlgListV1: 'ctlgListV1',
	cstrPostV1: 'cstrPostV1',
	usrPostV1: 'usrPostV1',
	ordListV1: 'ordListV1',
	ordTableV1: 'ordTableV1',
	chrtPostV1: 'chrtPostV1',
	rptPostV1: 'rptPostV1',
	ctraPostV1: 'ctraPostV1',
	// <--HERE
};

/**
 * Step 3: Add new element picker icon
 */
const elementPickerIcons = {
	[authorizedElementKeys.spvrPostV1]: ListIcon,
	[authorizedElementKeys.spvrListV1]: ListIcon,
	[authorizedElementKeys.ctlgListV1]: ListIcon,
	[authorizedElementKeys.cstrPostV1]: ListIcon,
	[authorizedElementKeys.usrPostV1]: ListIcon,
	[authorizedElementKeys.ordListV1]: ListIcon,
	[authorizedElementKeys.chrtPostV1]: ListIcon,
	[authorizedElementKeys.ctraPostV1]: ListIcon,
	[authorizedElementKeys.rptPostV1]: ListIcon,
};

/**
 * Step 2: Add custom element key value pairs
 */
const authorizedElementComponents = {
	[authorizedElementKeys.spvrPostV1]: lazy(
		() => import('./elements/Supervisor/Post/PostV1'),
	),
	[authorizedElementKeys.spvrListV1]: lazy(
		() => import('./elements/Supervisor/List/ListV1'),
	),
	[authorizedElementKeys.ctlgListV1]: lazy(
		() => import('./elements/Warehouse/Catalog/ListV1'),
	),
	[authorizedElementKeys.cstrPostV1]: lazy(
		() => import('./elements/Clients/ClientPostV1'),
	),
	[authorizedElementKeys.usrPostV1]: lazy(
		() => import('./elements/Users/UserPostV1/UserPostV1'),
	),
	[authorizedElementKeys.ordListV1]: lazy(
		() => import('./elements/Orders/OrderListV1'),
	),
	[authorizedElementKeys.chrtPostV1]: lazy(
		() => import('./elements/Analysis/ChartPostV1'),
	),
	[authorizedElementKeys.ctraPostV1]: lazy(
		() => import('./elements/Contrators/ContractorPostV1'),
	),
	[authorizedElementKeys.rptPostV1]: lazy(
		() => import('modules/Reports/elements/PostV1/PostV1'),
	),
	// <--HERE
};

class ElementBuilderConfig {
	static getAuthorizedElementKeys() {
		return authorizedElementKeys;
	}

	/**
	 * @param {Object[]} elements - The element array from selected screen
	 * @returns Authorized elements to use in rest of code
	 */
	static getAuthorizedElements(elements) {
		return GlobalUtils.checkArray(elements).filter(
			element => !!authorizedElementKeys[element.key], // Call to gateway to access the authorized element keys
		);
	}

	/**
	 * @param {int} elementKey - The element key from <feed_elements.key>
	 * @returns Authorized element React Node
	 */
	static getAuthorizedElementReactNode(elementKey) {
		return authorizedElementComponents[elementKey];
	}

	static getPickerIcon(elementKey) {
		return elementPickerIcons[elementKey];
	}

	static getSelectedElement(selectedElementId, authorizedElements) {
		const selectedElement = authorizedElements.find(
			({ id }) => id === selectedElementId,
		);
		return selectedElement;
	}
}

export default ElementBuilderConfig;
