// Libs
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withTheme } from 'styled-components';
// Components
import { Link } from 'react-router-dom';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Wrapper, Logo } from 'components';
// Utils
import AuthService from 'utils/libs/auth/AuthService';
import GENERAL from 'utils/constants/general';

// Actions
import * as actions from './actions';

// Styles
import './index.css';
const styles = () => ({
	textField: {
		width: '200px',
		minHeight: '48px',
		height: '48px',
		paddingLeft: '15px',
		backgroundColor: 'rgba(255,255,255,.07)',
		color: '#fff',
		marginBottom: '10px',
		fontFamily: 'Roboto',
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
});

const { ENV } = GENERAL;

class Signup extends Component {
	constructor(...props) {
		super(...props);

		this.Auth = new AuthService();
	}

	/** ****************** LIFECYCLES ********************/
	componentWillMount() {
		if (this.Auth.loggedIn()) {
			return <Redirect to='/' />;
		}
	}

	componentWillUnmount() {
		this.props.doReset();
	}

	/** ****************** JSX RENDER ********************/
	render() {
		const {
			classes,
			idc,
			name,
			phone,
			email,
			password,
			entityCode,
			status,
			loading,
			showPassword,
			mutateDirectProps,
			doSignup,
		} = this.props;

		return (
			<Wrapper
				className='animated fadeIn'
				padding='0'
				position='relative'
				flexDirection='column'
			>
				<Wrapper
					position='relative'
					flexDirection='column'
					alignItems='stretch'
					padding='4em 1.5em 1.5em 1.5em'
					maxWidth='370px'
					height='500px'
					borderRadius='50px'
					boxShadow='0 8px 16px 0 rgb(0 0 0)'
					mediumBackground
				>
					{/* LOGO */}
					<Logo position='absolute' left='28%' top='-55px' opacity='.7' />
					{/* IDENTIFICATION */}
					<Input
						required
						name='idc'
						placeholder='Identidad*'
						className={classes.textField}
						onChange={e => mutateDirectProps({ idc: e.target.value })}
						value={idc}
						type='text'
					/>
					{/* NAME */}
					<Input
						required
						name='name'
						placeholder='Nombre*'
						className={classes.textField}
						onChange={e => mutateDirectProps({ name: e.target.value })}
						value={name}
						type='text'
					/>
					{/* PHONE */}
					<Input
						className={classes.textField}
						name='phone'
						type='number'
						placeholder='Teléfono*'
						value={phone}
						onChange={e => mutateDirectProps({ phone: e.target.value })}
					/>
					{/* EMAIL */}
					<Input
						required
						className={classes.textField}
						name='email'
						type='text'
						placeholder='Correo*'
						value={email}
						onChange={e => mutateDirectProps({ email: e.target.value?.trim() })}
					/>
					{/* PASSWORD */}
					<Input
						required
						placeholder='Contraseña*'
						id='adornment-password'
						name='password'
						className={classes.textField}
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={e => mutateDirectProps({ password: e.target.value })}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									onClick={() =>
										mutateDirectProps({ showPassword: !showPassword })
									}
									onMouseDown={e => e.preventDefault()}
								>
									{showPassword ? (
										<VisibilityOff className={classes.icon} />
									) : (
										<Visibility className={classes.icon} />
									)}
								</IconButton>
							</InputAdornment>
						}
					/>
					{/* ENTITY CODE */}
					<Input
						required
						className={classes.textField}
						name='entityCode'
						type='text'
						placeholder='Código empresa*'
						value={entityCode}
						onChange={e =>
							mutateDirectProps({ entityCode: e.target.value?.trim() })
						}
					/>

					{/* LOGIN-BUTTON */}
					<div className='container-signup-form-action-buttons'>
						{loading ? (
							<div
								style={{
									maxWidth: '800px',
									marginTop: '15px',
									minWidth: 24,
									textAlign: 'center',
								}}
							>
								<CircularProgress
									variant='indeterminate'
									size={24}
									thickness={4}
								/>
							</div>
						) : (
							<div className='container-signup-form-action-buttons'>
								<Button
									className='container-signup-form-button'
									type='button'
									onClick={() =>
										doSignup(idc, name, phone, email, password, entityCode)
									}
									variant={status === 'error' ? 'contained' : 'outlined'}
									color={status === 'error' ? 'secondary' : 'primary'}
								>
									<span className='txt-white'>Registrarme</span>
								</Button>
								<div>
									<span className='txt-white' style={{ marginRight: '7px' }}>
										¿Ya tiene cuenta?
									</span>
									<Link to={ENV.ROUTES.PATHS.LOGIN}>Ingresar</Link>
								</div>
							</div>
						)}
					</div>
				</Wrapper>
			</Wrapper>
		);
	}
}

/** ****************** PROPTYPES ********************/
Signup.propTypes = {
	// Props
	classes: PropTypes.object.isRequired,
	// Functions
	mutateDirectProps: PropTypes.func.isRequired,
	doSignup: PropTypes.func.isRequired,
	doReset: PropTypes.func.isRequired,
};

/** ****************** EXPORT ********************/
export default connect(
	state => ({
		idc: state.signup.idc,
		name: state.signup.name,
		phone: state.signup.phone,
		email: state.signup.email,
		password: state.signup.password,
		entityCode: state.signup.entityCode,
		status: state.signup.status,
		loading: state.signup.loading,
		showPassword: state.signup.showPassword,
	}),
	actions,
)(withStyles(styles)(withTheme(Signup)));
