// Libs
import React from 'react';

export default ({ fill = 'white', fillOpacity = '0.54' }) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.99992 11.5134L12.1199 14L11.0266 9.31337L14.6666 6.16004L9.87325 5.75337L7.99992 1.33337L6.12659 5.75337L1.33325 6.16004L4.97325 9.31337L3.87992 14L7.99992 11.5134Z'
			fill={fill}
			fill-opacity={fillOpacity}
		/>
	</svg>
);
