// Libs
import React, { Suspense } from 'react';
// Components
import { Wrapper } from 'components';
// Config
import ElementBuilderConfig from './element.config';

const ElementBuilder = React.memo(
	({
		theme,
		isMobile,
		elementKey,
		screenKey,
		record,
		fields,
		profile,
		rootObserverRef,
	}) => {
		const Element =
			ElementBuilderConfig.getAuthorizedElementReactNode(elementKey);

		if (!Element) return null;

		return (
			<Wrapper padding='0' justifyContent='center'>
				<Suspense fallback={null}>
					<Element
						screenKey={screenKey}
						rootObserverRef={rootObserverRef}
						theme={theme}
						isMobile={isMobile}
						record={record}
						fields={fields}
						profile={profile}
					/>
				</Suspense>
			</Wrapper>
		);
	},
);

export default ElementBuilder;
