// Libs
import React from 'react';
// Utils
import GENERAL from 'utils/constants/general';
// import { debounce } from 'utils/libs';
import DataFieldManagerUtils from '../DataFieldManagerUtils';
// Hooks
import { useOrderZoneAssignment, useTheme } from 'hooks';
// Components
import { Space } from 'antd';
import { Menu, Dropdown, Span, LogoIcon, CloseIcon } from 'components';
import Updating from '../Updating';
import Status from '../Status';
// Styles
import styles from './ZoneId.module.css';

const { UNSUCCESS } = GENERAL.ENV.STATUS;
const { SERVICE_ID, EVENT_ID } = DataFieldManagerUtils.getFieldIds();

const Zones = ({ cities, onClick }) => (
	<Menu.Wrapper mode='inline'>
		{cities.map(c => (
			<Menu.SubMenu key={c.id} title={c.name}>
				{c.children.map(t => (
					<Menu.SubMenu key={`${c.id}.${t.id}`} title={t.name}>
						{t.children.map(z => (
							<Menu.Item
								key={`${c.id}.${t.id}.${z.id}`}
								onClick={e => {
									e.domEvent.stopPropagation();
									onClick({
										zoneId: z.id,
										zoneName: z.name,
										townshipId: t.id,
										townshipName: t.name,
										cityId: c.id,
										cityName: c.name,
									});
								}}
							>
								<Span>{z.name}</Span>
							</Menu.Item>
						))}
					</Menu.SubMenu>
				))}
			</Menu.SubMenu>
		))}
	</Menu.Wrapper>
);

const Selection = ({
	field,
	rowData,
	isLoading,
	selected,
	zones,
	onGetZoneAssignment,
	onSetZoneAssignment,
}) => {
	const { theme } = useTheme();

	const handleOnChange = option => {
		const orderId = rowData.id;

		if (field.onChange) {
			field.onChange({ option, rowData, selected });
		} else {
			onSetZoneAssignment({ option, orderId }, field);
		}
	};

	const filterData = {
		serviceId: rowData[SERVICE_ID],
		eventId: rowData[EVENT_ID],
	};

	return (
		<div className={styles.container}>
			<Dropdown.Wrapper
				trigger={['click']}
				onOpenChange={isOpen => isOpen && onGetZoneAssignment(filterData)}
				overlay={
					isLoading ? (
						<LogoIcon spin />
					) : (
						<Zones cities={zones} onClick={handleOnChange} />
					)
				}
			>
				<a
					style={{ color: theme._secondaryColor }}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<Space>
						{rowData[field.name] || rowData[field.id]}
						{!rowData[field.id] && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Span hoverable margin='0 5px 0 0'>
									Seleccione
								</Span>
								<CloseIcon />
							</div>
						)}
					</Space>
				</a>
			</Dropdown.Wrapper>
		</div>
	);
};

const ZoneId = ({ field, rowData, selected }) => {
	const { state, onGetZoneAssignment, onSetZoneAssignment, onRetry } =
		useOrderZoneAssignment();

	if (state.status === UNSUCCESS)
		return (
			<Status
				className={styles.container}
				message={state.message}
				onRetry={onRetry}
			/>
		);

	if (state.isSetting) return <Updating className={styles.container} />;

	return (
		<Selection
			field={field}
			rowData={rowData}
			isLoading={state.isLoading}
			selected={selected}
			zones={state.data}
			onGetZoneAssignment={onGetZoneAssignment}
			onSetZoneAssignment={onSetZoneAssignment}
		/>
	);
};

export default ZoneId;
