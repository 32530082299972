// Libs
import { useSelector } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { unifyDatetime } from 'utils/libs/dateFormats';
// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';
// Selectors
import {
	selectSelectedProjectId,
	selectSelectedOrderTargetFilterId,
} from 'core/selectors';
import { selectProfile } from 'screens/Login/selectors';

const { SUCCESS } = GENERAL.ENV.STATUS;

const oldEndpoint = '/orders';
const endpoint = '/orderManager';
const useOrderApi = () => {
	const selectedProjectId = useSelector(selectSelectedProjectId);
	const selectedOrderTargetFilterId = useSelector(
		selectSelectedOrderTargetFilterId,
	);
	const profile = useSelector(selectProfile);

	return {
		/**
		 * Get assignment data on create process
		 * @param {Object[]} orders
		 * @param {string} dateFormat
		 * @param {int} validationTemplateId
		 * @returns orders with user assignment
		 */
		getCreationAssignment: (orders, { dateFormat, validationTemplateId }) =>
			ApiServiceV2.post({
				url: `${endpoint}/v1/${selectedProjectId}/${selectedOrderTargetFilterId}/creationAssignment`,
				data: { orders, dateFormat, validationTemplateId },
			}),
		/**
		 * Create imported orders
		 * @param {Object[]} orders
		 * @param {string} dateFormat
		 * @param {int} validationTemplateId
		 * @returns orders with user assignment
		 */
		create: ({ orders, dateFormat, validationTemplateId }) =>
			ApiServiceV2.post({
				url: `${endpoint}/v1/${selectedProjectId}/${selectedOrderTargetFilterId}/create`,
				data: { orders, dateFormat, validationTemplateId },
			}),

		/**
		 * Get user assignment
		 * @param {Object[]} orders
		 * @param {int} serviceId
		 * @param {string} searchValue
		 * @returns orders[...].userAssignment
		 */
		getUserAssignment: (orders, { serviceId, searchValue }) => {
			const _orders = Buffer.from(JSON.stringify(orders)).toString('base64');
			const _searchValue = searchValue
				? Buffer.from(searchValue).toString('base64')
				: undefined;

			return ApiServiceV2.get({
				url: `${endpoint}/v1/${selectedProjectId}/${selectedOrderTargetFilterId}/${serviceId}/${_orders}/${_searchValue}/userAssignment`,
			});
		},
		/**
		 * Set user assignment
		 * @param {Object[]} orders
		 * @param {int} serviceId
		 * @param {Object} coords
		 * @returns {Object[]} orders
		 */
		setUserAssignment: (orders, { serviceId, coords }) => {
			return ApiServiceV2.put({
				url: `${endpoint}/v1/${selectedProjectId}/${selectedOrderTargetFilterId}/${serviceId}/userAssignment`,
				data: { orders, coords },
			});
		},

		/**
		 * Set event assignment
		 * @param {Object[]} orders
		 * @returns {Object[]} orders
		 */
		setEventAssignment: (orders, { coords }) => {
			return ApiServiceV2.put({
				url: `${endpoint}/v1/${selectedProjectId}/eventAssignment`,
				data: { orders, coords },
			});
		},

		/**
		 * Set event assignment
		 * @param {Object[]} orders
		 * @returns {Object[]} orders
		 */
		setEventAssignmentV0: (
			orders,
			{ eventId, coords, comment, reprogramDate, reprogramTime },
		) => {
			return ApiServiceV2.put({
				url: `${oldEndpoint}/event_admin`,
				data: {
					selectedOrders: orders.map(order => ({ order_id: order.id })),
					eventId,
					comment,
					reprogramAt: unifyDatetime(
						reprogramDate,
						reprogramTime,
						profile.organization.tz,
					),
					coords,
				},
			}).then(eventData =>
				orders.map(order => ({
					...order,
					status: SUCCESS,
				})),
			);
		},

		/**
		 * Set zone assignment
		 * @param {int} zoneId 
		 * @param {int} orderId
		 * @returns {Object} 
		 */
		setZoneAssignment: (zoneId, orderId) => {
			return ApiServiceV2.put({
				url: `${endpoint}/v1/${zoneId}/${orderId}/zoneAssignment`,
				data: { zoneId, orderId },
			});
		},

		/**
		 * Get zone assignment
		 * @param {Object[]} orders
		 * @param {int} serviceId
		 * @param {string} searchValue
		 * @returns orders[...].zoneAssignment
		 */
		getZoneAssignment: ({ searchValue }) => {
			const _searchValue = !!searchValue
				? Buffer.from(searchValue).toString('base64')
				: undefined;

			return ApiServiceV2.get({ url: `zones/nestedTotalZones` });
		},

		/**
		 * Get zone assignment
		 * @param {int} orderId
		 * @returns Object[]
		 */
		getOrderHistory: orderId =>
			ApiServiceV2.get({
				url: `${oldEndpoint}/history/${orderId}`,
			}),
		/**
		 * Set Details
		 * @param {int} orderId
		 * @returns Object[]
		 */
		setOrderDetails: ({ orderId, rowData }) =>
			ApiServiceV2.post({
				url: `${oldEndpoint}/details/${orderId}`,
				data: { rowData },
			}),

		/**
		 * Get zone assignment
		 * @param {int} orderId
		 * @returns Object[]
		 */
		getBillingMaterials: orderId =>
			ApiServiceV2.get({
				url: `/warehouses_transactions_docs/getBillingMaterials/${orderId}`,
			}),
	};
};

export default useOrderApi;
