// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Immutable from 'immutable';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Hooks
import { useToastMessage } from 'hooks';
// Components
import { Wrapper } from 'components';
import { DefaultForm } from 'core/FormBuilder';
import ImportPreview from './ImportPreview';
import ImportButton from './ImportButton';
import DateFormat from './DateFormat';

const initialState = {
	dateFormat: undefined,
	preview: undefined,
	orders: [],
	filters: {},
};

function ImportOrders({
	isMobile,
	form,
	values,
	setValues,
	submitForm,
	getProcessedComponentSqlData,
}) {
	const sendToast = useToastMessage();

	const profile = useSelector(selectProfile);

	const [state, _setState] = useState(initialState);
	const setState = props => _setState(prev => ({ ...prev, ...props }));

	const { templateId: validationTemplateId, templates = [] } = values;
	const templateValidator = templates.find(
		template => template.id === validationTemplateId,
	);

	const onResetOrders = () =>
		_setState(prev => ({ ...initialState, dateFormat: prev.dateFormat }));

	const onDeleteRow = ids => {
		_setState(prev => ({
			...prev,
			orders: prev.orders.filter(o => !ids.includes(o.id)),
		}));
	};

	const _orders = useMemo(
		() => Immutable.List(state.orders).toJS(),
		[state.orders],
	);

	useEffect(() => {
		if (!_orders.length) onResetOrders();
	}, [_orders.length]);

	return state.preview ? (
		<ImportPreview
			isMobile={isMobile}
			orders={_orders}
			filters={state.filters}
			dateFormat={state.dateFormat}
			templateValidator={templateValidator}
			onSetState={setState}
			onResetOrders={onResetOrders}
			onDeleteRow={onDeleteRow}
			sendToast={sendToast}
		/>
	) : (
		<Wrapper padding='0' flexDirection='column' width='270px'>
			<DefaultForm
				isMobile={isMobile}
				externalDisabled={_orders.length > 0}
				form={form}
				values={values}
				setValues={setValues}
				submitForm={submitForm}
				getProcessedComponentSqlData={getProcessedComponentSqlData}
			/>
			<DateFormat
				dateFormat={state.dateFormat}
				disabled={!validationTemplateId || !!_orders.length}
				onChange={value => setState({ dateFormat: value })}
			/>
			<ImportButton
				disabled={!state.dateFormat}
				dateFormat={state.dateFormat}
				templateValidator={templateValidator}
				profile={profile}
				formComponentValues={values}
				onSetState={setState}
				onResetOrders={onResetOrders}
				sendToast={sendToast}
			/>
		</Wrapper>
	);
}

export default ImportOrders;
