// Libs
import React from 'react';
// Components
import { InputNumber } from 'components';

const InventoryTransactionValue = ({ rowData, cart, setState }) => {
	const isItemAdded = item => item.item_id === rowData.id;

	const idx = cart.findIndex(isItemAdded);

	function onChangeArticleQuantity(value) {
		if (!value || value === 0) {
			if (idx !== -1)
				setState({
					cart: cart.filter(article => article.item_id !== rowData.id),
				});
		} else {
			if (idx === -1) {
				cart.push({
					item_id: rowData.id,
					amount: value,
				});
			} else {
				cart[idx].amount = value;
			}
			setState({
				cart: cart,
			});
		}
	}

	return (
		<InputNumber
			placeholder={'Cantidad'}
			value={idx !== -1 ? cart[idx].amount : undefined}
			min={0}
			max={rowData.amount}
			onChange={onChangeArticleQuantity}
		/>
	);
};

export default InventoryTransactionValue;
