// Libs
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Utils
import GENERAL from 'utils/constants/general';
import { GlobalUtils } from 'utils';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
import { updateDatasourceItem } from 'core/actions';
// Selectors
import { selectDatasourceData } from 'core/selectors';
// Hooks
import useOrderApi from './useOrderApi';

const { UNSUCCESS } = GENERAL.ENV.STATUS;

const useOrderZoneAssignment = () => {
	const dispatch = useDispatch();
	const { getZoneAssignment, setZoneAssignment } = useOrderApi();
	const datasource = useSelector(selectDatasourceData);

	const [state, setState] = useState({
		isLoading: false,
		isSetting: false,
		data: [],
		status: undefined,
		message: undefined,
	});

	const onRetry = () =>
		setState(prev => ({ ...prev, status: undefined, message: undefined }));

	const onGetZoneAssignment = ({ searchValue }) => {
		setState(prev => ({ ...prev, isLoading: true }));
		getZoneAssignment({ searchValue })
			.then(res =>
				setState(prev => ({
					...prev,
					isLoading: false,
					data: GlobalUtils.checkArray(res),
				})),
			)
			.catch(err =>
				dispatch(sendToast({ message: err.message, type: err.type })),
			);
	};

	const onSetZoneAssignment = async ({ option, orderId }, field) => {
		setState(prev => ({ ...prev, isSetting: true }));

		try {
			const response = await setZoneAssignment(option.id, orderId);

			if (response.status === UNSUCCESS) {
				setState(prev => ({
					...prev,
					isSetting: false,
					status: response.status,
					message: response.message,
				}));
				return;
			}

			dispatch(
				updateDatasourceItem({
					updateRecords: [
						{
							id: response.data.orderId,
							fields: {
								[field.id]: Number(response.data.zoneId),
								[field.name]: response.data.zoneName,
								townshipName: response.data.townshipName,
								cityName: response.data.cityName,
							},
						},
					],
				}),
			);
			field?.setReload?.(true);
			setState(prev => ({ ...prev, isSetting: false }));
		} catch (err) {
			dispatch(sendToast({ message: err.message, type: err.type }));
			setState(prev => ({
				...prev,
				isSetting: false,
				status: UNSUCCESS,
				message: err.message || '',
			}));

			throw err;
		}
	};

	return { state, onGetZoneAssignment, onSetZoneAssignment, onRetry };
};

export default useOrderZoneAssignment;
