// Libs
import React from 'react';
// Utils
import { formatDate } from 'utils/libs/dateFormats';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Hooks
import { useSelector } from 'react-redux';
import useTheme from 'hooks/useTheme';
// Components
import { Caption } from 'components';
// Styles
import styles from './Watermark.module.css';

const Watermark = ({ coords, updatedAt, logo }) => {
	const { theme } = useTheme();
	const profile = useSelector(selectProfile);
	const hasCoord = coords?.latitude && coords.longitude;

	return (
		<>
			<div
				className={styles.waterMark}
				style={{
					backgroundColor: theme._tranparentOverlayBackgroundColor,
				}}
				onClick={e => e.stopPropagation()}
			>
				{hasCoord && (
					<div>
						<Caption fontWeight='bold' margin='0 8px 0 0'>
							{Number(coords.latitude).toFixed(6)},
						</Caption>
						<Caption fontWeight='bold'>
							{Number(coords.longitude).toFixed(6)}
						</Caption>
					</div>
				)}
				<Caption fontWeight='bold'>
					{formatDate(
						coords?.createdAt || updatedAt,
						profile,
						'ddd DD-MMM HH:mm',
					)}
				</Caption>
			</div>
			<div
				style={{ backgroundColor: theme._secondaryBackgroundColor }}
				className={styles.containerImg}
			>
				<img src={logo} alt='' style={{ width: '100%' }} />
			</div>
		</>
	);
};
export default Watermark;
