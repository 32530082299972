// Libs
import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Input from '@mui/material/Input';
import LinearProgress from '@mui/material/LinearProgress';
import WithAuth from 'utils/libs/auth/WithAuth';
import { lsHandler } from 'utils/libs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	AutoSyncOfflineData,
	Wrapper,
	FeedIcon,
	CloseIcon,
	SearchIcon,
	EditProfileDrawer,
} from 'components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import GENERAL from 'utils/constants/general';
// Utils
import KEYWORD_FEED from 'core/keywords';
import RouteWithSubRoutes from 'utils/routes/RouteWithSubRoutes';
import { OrderUtils } from 'utils';
import {
	getAuditedTransactionDocs,
	doReset as doResetCollector,
	resetAuditedTransactionDocs,
} from 'components/Collector/actions';
// Selectors
import { selectCoins } from 'store/api/selectors';
import { selectIsMobile } from 'screens/Dashboard/selectors';
// Context
import { ElementViewBuilderProvider } from 'core/context';
// Hooks
import { useActiveApp } from 'hooks';
// Components
import ToggleContainer from 'core/MenuBuilder/ToggleContainer';
import ProfileSection from 'core/MenuBuilder/ProfileSection';
import MenuContainer from 'core/MenuBuilder/MenuContainer';
import { ElementViewBuilder } from 'core/ElementViewBuilder';
// Actions
import * as apiActions from 'store/api/actions';
import * as dashboardActions from './actions';
import { Toolbar, SubToolbar, ShowErrors } from './subs';
// Styles
import './index.css';
import * as loginActions from '../Login/actions';
import * as orderActions from '../Orders/actions';
import * as userActions from '../Users/actions';
import * as billingActions from '../Billings/actions';
import * as warehouseActions from '../Warehouses/actions';
import { onKeyPressedSearchStockSeries } from '../Warehouses/utils';

const { ENV } = GENERAL;

/** ********************************* JSX COMPONENTS **************************************/

const ItemDashboard = props => {
	const {
		// GENERAL
		history,
		route,
		// DASHBOARD
		expandedDashboardItem,
		modeView,
		mutateDirectProps,
		// ORDERS
		orders,
		mutate1ObjectInOrders,
		getControlOrders,
		// WAREHOUSES
		warehouses,
		mutate1ObjectInWarehouses,
	} = props;
	const { item, id, routes, noContainer } = route;

	if (!item) return null;

	if (!routes || routes.length === 0 || noContainer) {
		return (
			<LinkItemDashboard
				{...{
					// GENERAL
					history,
					route,
					// DASHBOARD
					modeView,
					mutateDirectProps,
					// ORDERS
					orders,
					mutate1ObjectInOrders,
					getControlOrders,
					// WAREHOUSES
					warehouses,
					mutate1ObjectInWarehouses,
				}}
			/>
		);
	}

	return (
		<div className='dashboard-head-sublist-items'>
			{/* CONTAINER ITEM */}
			<ListItem
				button
				style={{ paddingLeft: 0 }}
				onClick={() =>
					mutateDirectProps({
						expandedDashboardItem:
							expandedDashboardItem !== id ? id : undefined,
					})
				}
			>
				{/* {
              ? <div className="expand-icon" />
              : <div className="collapse-icon" />
          } */}
				{expandedDashboardItem === id ? (
					<div className='expand-icon' />
				) : (
					<div className='collapse-icon' />
				)}
				<ListItemText
					style={{ paddingLeft: 0 }}
					primary={
						<span className='dashboard-head-subitem-text'>
							{item.toUpperCase()}
						</span>
					}
				/>
			</ListItem>

			{/* CONTAINER COLLAPSE */}
			<Collapse in={expandedDashboardItem === id} timeout='auto' unmountOnExit>
				<List component='div'>
					{routes.map(
						(route, idxL2) =>
							route.item && (
								<LinkItemDashboard
									key={idxL2}
									{...{
										// GENERAL
										history,
										route,
										// DASHBOARD
										modeView,
										mutateDirectProps,
										// ORDERS
										orders,
										mutate1ObjectInOrders,
										getControlOrders,
										// WAREHOUSES
										warehouses,
										mutate1ObjectInWarehouses,
									}}
								/>
							),
					)}
				</List>
			</Collapse>
		</div>
	);
};

const LinkItemDashboard = props => {
	const {
		// GENERAL
		history,
		route,
		// DASHBOARD
		modeView,
		mutateDirectProps,
		// ORDERS
		orders,
		mutate1ObjectInOrders,
		getControlOrders,
		// WAREHOUSES
		warehouses,
		mutate1ObjectInWarehouses,
	} = props;
	const { control, getOrdersQueryModal } = orders;
	const { stockSeriesManage } = warehouses;
	const { path, item, id, icon: RouteIcon } = route;

	return (
		<div className='dashboard-item'>
			{id === ENV.ROUTES.IDS.ORDERS && control.searchInData.renderToolbar ? (
				<div style={{ width: '100%' }}>
					<Input
						id='toolbar-search-order-input'
						style={{ width: '95%', marginLeft: '17px', height: '44px' }}
						autoFocus
						name='data'
						placeholder='Buscar orden'
						value={control.searchInData.data}
						onKeyPress={e =>
							OrderUtils.onKeyPressedSearchOrder(
								e,
								control.searchInData,
								history,
								modeView,
								mutate1ObjectInOrders,
								mutateDirectProps,
							)
						}
						onChange={e =>
							mutate1ObjectInOrders('control', {
								searchInData: {
									...control.searchInData,
									[e.target.name]: e.target.value,
								},
							})
						}
						endAdornment={
							<CloseIcon
								button
								onClick={() =>
									OrderUtils.onClickCloseSearchButton(
										mutate1ObjectInOrders,
										control.searchInData,
										getControlOrders,
										getOrdersQueryModal,
									)
								}
							/>
						}
					/>
					{control.searchInData.searchBy === 'deepSearch' && (
						<LinearProgress style={{ width: '100%', height: '1px' }} />
					)}
				</div>
			) : id === ENV.ROUTES.IDS.WAREHOUSES_MANAGE &&
			  stockSeriesManage.searchInData.renderToolbar ? (
				<div style={{ width: '100%' }}>
					<Input
						id='toolbar-search-order-input'
						style={{ width: '95%', marginLeft: '17px', height: '65px' }}
						autoFocus
						name='data'
						placeholder='Buscar series'
						value={stockSeriesManage.searchInData.data}
						onKeyPress={e =>
							onKeyPressedSearchStockSeries(
								e,
								stockSeriesManage.searchInData,
								history,
								modeView,
								mutate1ObjectInWarehouses,
								mutateDirectProps,
							)
						}
						onChange={e =>
							mutate1ObjectInWarehouses('stockSeriesManage', {
								searchInData: {
									...stockSeriesManage.searchInData,
									[e.target.name]: e.target.value,
								},
							})
						}
						endAdornment={
							<CloseIcon
								button
								onClick={() =>
									mutate1ObjectInWarehouses('stockSeriesManage', {
										searchInData: {
											...stockSeriesManage.searchInData,
											renderToolbar: false,
											searchBy: 'all',
											data: '',
										},
									})
								}
							/>
						}
					/>
					{stockSeriesManage.searchInData.searchBy === 'scan' && (
						<LinearProgress style={{ width: '100%', height: '1px' }} />
					)}
				</div>
			) : (
				<Link
					className='link-slidemenu-item'
					to={path}
					onClick={() => mutateDirectProps({ isOpen: false })}
				>
					{/* Container ITEM */}
					<ListItem button style={{ width: '100%' }}>
						{/* Container ICON and TEXT item */}
						<div className='dashboard-item-icon-and-text'>
							<ListItemIcon>
								{RouteIcon ? (
									<RouteIcon />
								) : (
									<div className={getClassIcons(id)} />
								)}
							</ListItemIcon>
							<ListItemText
								primary={<span className='dashboard-item-text'>{item}</span>}
							/>
						</div>
					</ListItem>
				</Link>
			)}

			{id === ENV.ROUTES.IDS.ORDERS && !control.searchInData.renderToolbar && (
				<SearchIcon
					onClick={() =>
						OrderUtils.onClickSearchButton(
							mutate1ObjectInOrders,
							control.searchInData,
						)
					}
				/>
			)}
			{id === ENV.ROUTES.IDS.WAREHOUSES_MANAGE &&
				!stockSeriesManage.searchInData.renderToolbar && (
					<SearchIcon
						onClick={() =>
							mutate1ObjectInWarehouses('stockSeriesManage', {
								searchInData: {
									...stockSeriesManage.searchInData,
									renderToolbar: true,
									searchBy: 'scan',
								},
							})
						}
					/>
				)}
		</div>
	);
};

const getClassIcons = id => {
	return id === ENV.ROUTES.IDS.USERS
		? 'account-icon'
		: id === ENV.ROUTES.IDS.COMMAND_CENTER
		? 'gps-point-icon'
		: id === ENV.ROUTES.IDS.ORDERS
		? 'orders-icon'
		: id === ENV.ROUTES.IDS.BILLINGS_PAYMENTS
		? 'billings-icon'
		: id === ENV.ROUTES.IDS.BILLINGS_INVOICES
		? 'billings-icon'
		: id === ENV.ROUTES.IDS.WAREHOUSES_MANAGE
		? 'warehouse-icon'
		: id === ENV.ROUTES.IDS.WAREHOUSES_CONTROL_SERIES
		? 'series-icon'
		: id === ENV.ROUTES.IDS.WAREHOUSES_ARTICLES
		? 'articles-icon'
		: id === ENV.ROUTES.IDS.WAREHOUSES_TRANSACTIONS
		? 'transaction-icon'
		: id === ENV.ROUTES.IDS.REPORTS
		? 'reports-icon'
		: id === ENV.ROUTES.IDS.ANALYTICS
		? 'analytics-icon'
		: id === ENV.ROUTES.IDS.SUPERVISOR && 'supervisor-icon';
};

const Viewers = React.memo(({ routes }) => {
	const _routes = React.useMemo(
		() => routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />),
		[],
	);

	return _routes;
});

const ActiveApp = ({ getNotifications }) => {
	const [active] = useActiveApp();

	useEffect(() => {
		if (!active) return;
		getNotifications();
	}, [active]);

	return <div />;
};

class Dashboard extends Component {
	constructor(...props) {
		super(...props);

		this.loadLocalStorage = this.loadLocalStorage.bind(this);
	}

	/** ********************************* LIFECYCLES **************************************/
	componentDidMount() {
		// Load Local Storage
		this.loadLocalStorage();
		// Get ReportMe
		this.props.reportMe(
			this.props.profile.user.id,
			this.props.profile.user.name,
		);
		// Get My Profile
		this.props.getMyProfile();
		// Get Users
		this.props.getOrganizationUsers();
		// Get notifications
		this.props.getNotifications();
	}

	componentWillUnmount() {
		this.props.doReset();
	}

	/** ********************************* FUNCTIONS **************************************/
	loadLocalStorage() {
		this.props.setMyProfile(lsHandler.get('synapse_profile'));
	}

	/** ********************************* JSX RENDERS **************************************/
	render() {
		const {
			// GENERAL
			getNotifications,
			history,
			routes,
			// LOGIN
			doLogout,
			profile,
			// DASHBOARD
			isOpen,
			showNavBar,
			expandedDashboardItem,
			mutateDirectProps,
			mutate1Object,
			modeView,
			sendToast,
			showErrors,
			// USERS
			users,
			mutate1ObjectInUsers,
			// ORDERS
			orders,
			mutate1ObjectInOrders,
			getControlOrders,
			getCountOrdersToReassignment,
			updateOrdersSort,
			onResetEnterNewOrders,
			// BILLINGS
			payments,
			mutate1ObjectInBillings,
			updatePaymentOrders,
			// WAREHOUSES
			warehouses,
			mutate1ObjectInWarehouses,
			resetAdjustArticles,
			resetCreateTransaction,
			sendTransferTransaction,
			updateTransaction,
			deleteTransaction,
			onGetOrderDataFromUninstalledSerie,
			// API
			api,
			getWarehouses,
			getArticles,
			getPaycuts,
			getPayments,
			getInvoicePays,
			getPaymentResume,
			getPaycutResume,
			getPaymentLines,
			getPaymentOrders,
			getStockArticles,
			getStockSeries,
			getInventoryToTransfer,
			getTransactionDocs,
			getItemsTransactionDocs,
			getOrganizationUsers,
			signWTD,
			mutate1ObjectApi,
		} = this.props;

		return (
			<Wrapper
				padding='0'
				className='animated fadeIn'
				width='100%'
				height='100vh'
				flexDirection='column'
				alignItems='stretch'
				position='relative'
			>
				<Toolbar
					// GENERAL
					history={history}
					// LOGIN
					profile={profile}
					// DASHBOARD
					modeView={modeView}
					showNavBar={showNavBar}
					mutateDirectProps={mutateDirectProps}
					sendToast={sendToast}
					// USERS
					users={users}
					mutate1ObjectInUsers={mutate1ObjectInUsers}
					// ORDERS
					orders={orders}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					getControlOrders={getControlOrders}
					onResetEnterNewOrders={onResetEnterNewOrders}
					// BILLINGS
					payments={payments}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					// WAREHOUSES
					warehouses={warehouses}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					resetCreateTransaction={resetCreateTransaction}
					onGetOrderDataFromUninstalledSerie={
						onGetOrderDataFromUninstalledSerie
					}
					// API
					api={api}
					getPaycuts={getPaycuts}
					getPayments={getPayments}
					getInvoicePays={getInvoicePays}
					getOrganizationUsers={getOrganizationUsers}
					mutate1ObjectApi={mutate1ObjectApi}
				/>
				<hr className='dashboard-line-separator' />
				<SubToolbar
					// GENERAL
					history={history}
					// LOGIN
					profile={profile}
					// DASHBOARD
					sendToast={sendToast}
					modeView={modeView}
					// USERS
					users={users}
					mutate1ObjectInUsers={mutate1ObjectInUsers}
					// ORDERS
					orders={orders}
					mutate1ObjectInOrders={mutate1ObjectInOrders}
					getControlOrders={getControlOrders}
					updateOrdersSort={updateOrdersSort}
					getCountOrdersToReassignment={getCountOrdersToReassignment}
					onResetEnterNewOrders={onResetEnterNewOrders}
					// BILLINGS
					payments={payments}
					mutate1ObjectInBillings={mutate1ObjectInBillings}
					updatePaymentOrders={updatePaymentOrders}
					getInvoicePays={getInvoicePays}
					// WAREHOUSES
					warehouses={warehouses}
					mutate1ObjectInWarehouses={mutate1ObjectInWarehouses}
					resetAdjustArticles={resetAdjustArticles}
					sendTransferTransaction={sendTransferTransaction}
					updateTransaction={updateTransaction}
					deleteTransaction={deleteTransaction}
					// API
					api={api}
					getPaycuts={getPaycuts}
					getPayments={getPayments}
					getPaymentResume={getPaymentResume}
					getPaycutResume={getPaycutResume}
					getPaymentLines={getPaymentLines}
					getPaymentOrders={getPaymentOrders}
					getWarehouses={getWarehouses}
					getArticles={getArticles}
					getStockArticles={getStockArticles}
					getStockSeries={getStockSeries}
					getInventoryToTransfer={getInventoryToTransfer}
					getTransactionDocs={getTransactionDocs}
					getItemsTransactionDocs={getItemsTransactionDocs}
					getOrganizationUsers={getOrganizationUsers}
					signWTD={signWTD}
					mutate1ObjectApi={mutate1ObjectApi}
				/>
				<Viewers routes={routes} />
				<ToggleContainer isOpen={isOpen}>
					<ProfileSection
						profile={profile}
						toggleDatasource={() => mutateDirectProps({ isOpen: !isOpen })}
						// onAvatarClick={() => {
						//   mutate1Object("editProfileDrawer", {
						//     isOpen: true,
						//   });
						// }}
					/>
					<hr className='dashboard-line-separator' />
					<MenuContainer>
						<div className='dashboard-items-container'>
							<List component='nav'>
								{routes
									.filter(
										r =>
											r.id !== ENV.ROUTES.IDS.SUPERVISOR &&
											r.id !== ENV.ROUTES.IDS.USERS &&
											r.id !== ENV.ROUTES.IDS.ANALYTICS,
									)
									.map((route, idxL1) => (
										<ItemDashboard
											key={idxL1}
											{...{
												// GENERAL
												history,
												route,
												// DASHBOARD
												expandedDashboardItem,
												modeView,
												mutateDirectProps,
												// ORDERS
												orders,
												mutate1ObjectInOrders,
												getControlOrders,
												// WAREHOUSES
												warehouses,
												mutate1ObjectInWarehouses,
											}}
										/>
									))}
							</List>

							<List>
								{/* MARKETPLACE */}
								{/* <Link
                      className="link-slidemenu-item"
                      to={ENV.ROUTES.PATHS.MARKETPLACE}
                      onClick={() => mutateDirectProps({ isOpen: false })}
                    >
                      <ListItem
                        // id='Surface'
                        button
                        key={"marketplace"}
                      >
                        <div className="dashboard-item">
                          <ListItemIcon>
                            <div className="marketplace-icon" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span className="dashboard-item-text">
                                {ENV.ROUTES.ITEMS.MARKETPLACE}
                              </span>
                            }
                          />
                        </div>
                      </ListItem>
                    </Link> */}

								{/* FEED */}
								<Link
									className='link-slidemenu-item'
									to={KEYWORD_FEED.ROUTES.FEED.PATH}
									onClick={() => mutateDirectProps({ isOpen: false })}
								>
									<ListItem button key={KEYWORD_FEED.ROUTES.FEED.ID}>
										<div className='dashboard-item'>
											<ListItemIcon>
												<FeedIcon />
											</ListItemIcon>
											<ListItemText
												primary={
													<span className='dashboard-item-text'>
														{KEYWORD_FEED.ROUTES.FEED.ITEM}
													</span>
												}
											/>
										</div>
									</ListItem>
								</Link>

								{/* EXIT BUTTON */}
								<ListItem
									button
									onClick={() => {
										doLogout();
									}}
								>
									<div className='dashboard-item'>
										<ListItemIcon>
											<div className='exit-icon' />
										</ListItemIcon>
										<ListItemText
											primary={
												<span className='dashboard-item-text'>Salir</span>
											}
										/>
									</div>
								</ListItem>
							</List>
						</div>
					</MenuContainer>
				</ToggleContainer>

				{/* Show Errors Drawer */}
				<ShowErrors showErrors={showErrors} mutate1Object={mutate1Object} />
				<AutoSyncOfflineData />
				<ActiveApp getNotifications={getNotifications} />
				<EditProfileDrawer />
			</Wrapper>
		);
	}
}

/** ********************************* PROPTYPES **************************************/
Dashboard.propTypes = {
	// Props
	routes: PropTypes.array.isRequired,
	isOpen: PropTypes.bool.isRequired,
	profile: PropTypes.object.isRequired,
	// Functions
	showNavBar: PropTypes.func.isRequired,
	reportMe: PropTypes.func.isRequired,
	sendToast: PropTypes.func.isRequired,
	doLogout: PropTypes.func.isRequired,
	doReset: PropTypes.func.isRequired,
};

const actions = {
	...dashboardActions,
	...apiActions,
	// LOGIN
	getMyProfile: loginActions.getMyProfile,
	setMyProfile: loginActions.setMyProfile,
	doLogout: loginActions.doLogout,
	// USERS
	mutate1ObjectInUsers: userActions.mutate1Object,
	// ORDERS
	getControlOrders: orderActions.getControlOrders,
	mutate1ObjectInOrders: orderActions.mutate1Object,
	mutateDirectPropsInOrders: orderActions.mutateDirectProps,
	getCountOrdersToReassignment: orderActions.getCountOrdersToReassignment,
	updateOrdersSort: orderActions.updateOrdersSort,
	getPhotoReport: orderActions.getPhotoReport,
	getOrder: orderActions.getOrder,
	onResetEnterNewOrders: orderActions.onResetEnterNewOrders,
	// BILLINGS
	mutate1ObjectInBillings: billingActions.mutate1Object,
	updatePaymentOrders: billingActions.updatePaymentOrders,
	// WAREHOUSES
	mutate1ObjectInWarehouses: warehouseActions.mutate1Object,
	sendTransferTransaction: warehouseActions.sendTransferTransaction,
	resetAdjustArticles: warehouseActions.resetAdjustArticles,
	resetCreateTransaction: warehouseActions.resetCreateTransaction,
	updateTransaction: warehouseActions.updateTransaction,
	deleteTransaction: warehouseActions.deleteTransaction,
	onGetOrderDataFromUninstalledSerie:
		warehouseActions.onGetOrderDataFromUninstalledSerie,
	// COLLECTOR
	getAuditedTransactionDocs,
	doResetCollector,
	resetAuditedTransactionDocs,
};

const DashboardContainer = props => (
	<ElementViewBuilderProvider>
		<Dashboard {...props} />
		<ElementViewBuilder isMobile={props.isMobile} />
	</ElementViewBuilderProvider>
);

export default WithAuth(
	connect(
		state => ({
			...state.dashboard,
			// -------------------------------------EXTERNAL PROPS------------------------------------
			// LOGIN
			profile: state.login.profile,
			// ORDERS
			orders: {
				control: {
					...state.orders.control,
				},
				ordersReassignmentModal: {
					...state.orders.ordersReassignmentModal,
				},
				getOrdersQueryModal: {
					...state.orders.getOrdersQueryModal,
				},
				getOrderInformationModal: {
					...state.orders.getOrderInformationModal,
				},
				enterNewOrdersModal: {
					...state.orders.enterNewOrdersModal,
				},
			},
			// USERS
			users: {
				control: {
					...state.users.control,
				},
			},
			// BILLINGS
			payments: {
				paycuts: {
					...state.billings.paycuts,
				},
				pays: {
					...state.billings.pays,
				},
				lines: {
					...state.billings.lines,
				},
				orders: {
					...state.billings.orders,
				},
				getPaycutsResumeModal: {
					...state.billings.getPaycutsResumeModal,
				},
				getPaycutsModal: {
					...state.billings.getPaycutsModal,
				},
				enterInvoiceOrdersModal: {
					...state.billings.enterInvoiceOrdersModal,
				},
			},
			// WAREHOUSES
			warehouses: {
				control: {
					...state.warehouses.control,
				},
				stockArticlesManage: {
					...state.warehouses.stockArticlesManage,
				},
				stockSeriesManage: {
					...state.warehouses.stockSeriesManage,
				},
				articlesManage: {
					...state.warehouses.articlesManage,
				},
				transactionsManage: {
					...state.warehouses.transactionsManage,
				},
				inventoryToTransferManage: {
					...state.warehouses.inventoryToTransferManage,
				},
				itemsTransactionDoc: {
					...state.warehouses.itemsTransactionDoc,
				},
				createTransaction: {
					...state.warehouses.createTransaction,
				},
				signWTDModal: {
					...state.warehouses.signWTDModal,
				},
				createPackage: {
					...state.warehouses.createPackage,
				},
				createNewEntryItem: {
					...state.warehouses.createNewEntryItem,
				},
				getTransactionsModal: {
					...state.warehouses.getTransactionsModal,
				},
				controlSeries: {
					...state.warehouses.controlSeries,
				},
				inventoryReception: {
					...state.warehouses.inventoryReception,
				},
			},
			// APIDATA
			api: {
				coins: selectCoins(state),
				users: {
					...state.api.users,
				},
				payments: {
					...state.api.payments,
				},
				gains: {
					...state.api.gains,
				},
				itemStates: state.api.itemStates,
				articles: {
					...state.api.articles,
				},
				warehouses: {
					...state.api.warehouses,
				},
				stock: {
					...state.api.stock,
				},
				transactions: {
					...state.api.transactions,
				},
				templates: {
					...state.api.templates,
				},
				warehousePackages: {
					...state.api.warehousePackages,
				},
				warehouseAreas: {
					...state.api.warehouseAreas,
				},
				warehouseZones: {
					...state.api.warehouseZones,
				},
			},
			isMobile: selectIsMobile(state),
		}),
		actions,
	)(DashboardContainer),
);
