// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { sendToast } from 'screens/Dashboard/actions';
// Components
import _CopyToClipboard from 'react-copy-to-clipboard';

const CopyToClipboard = ({ children, value }) => {
	const dispatch = useDispatch();
	return (
		<_CopyToClipboard
			text={value}
			onCopy={() => value && dispatch(sendToast({ message: 'Texto copiado' }))}
		>
			{children}
		</_CopyToClipboard>
	);
};

export default CopyToClipboard;
